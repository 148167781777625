import React, {FunctionComponent} from "react";
import {WrapperLogoMicrosoft } from "./styles";
import {ILogoMicrosoftPartherHome} from './interface'

export const LogoMicrosoftPartherHome: FunctionComponent<ILogoMicrosoftPartherHome> = ({image}) => {
    return <>
        <WrapperLogoMicrosoft>
            <img src={image} />
        </WrapperLogoMicrosoft>
    </>
}
