import React, {FunctionComponent} from "react";
import {IListItem} from "./interfaces";
import {ListOfItem, ListItemName, CheckIcon, WrapperListItems, ContainerListCheck} from "./styles";
import ReactHtmlParser from 'react-html-parser';

export const ListCheck: FunctionComponent<IListItem> = ({title, items}) => {
    return <>
        <ContainerListCheck>
            <h2>{ReactHtmlParser(title)}</h2>
            <WrapperListItems>
                {Object.keys(items).map((keyName, i) => (
                    <ListOfItem>
                        <ListItemName>
                            <CheckIcon/>
                            <h4>{items[keyName].data['title']}</h4>
                        </ListItemName>
                        <p>{ReactHtmlParser(items[keyName].data['content'])}</p>
                    </ListOfItem>
                ))}
            </WrapperListItems>
        </ContainerListCheck>
    </>
}
