import styled from "styled-components";
import {media} from "../../ui/atoms/theme";

export const WrapperTabsAreas = styled.div`
  margin-top: 40px;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: start;
    list-style-type: none;
    padding: 0;
  }

  .react-tabs__tab {
    cursor: pointer;
    &.react-tabs__tab--selected {
      position: relative;
      color: black;

      &:after {
        content: '';
        display: block;
        height: 6px;
        width: 100%;
        background: #FF5A00;
        position: absolute;

      }
    }
  }

  li {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin-right: 20px;
    color: #B3BEC4;

    &:last-child {
      margin: 0;
    }
  }

  ${media.up("md")} {
    margin-top: 60px;

    li {

      margin-right: 40px;
      font-size: 34px;
      line-height: 42px;
    }
  }
`
