import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperVimeoGala = styled.div`
    width: 100%;
    height: 700px;
    position relative;
    background: #25282B;
    margin: 50px 0;
    iframe{
        width: 100%;
        height: 100%;
        border: none;
    }
    ${media.down('xl')} {
        height: 450px;
    }
    ${media.down('lg')} {
        height: 300px;
    }
    ${media.down('md')} {
        height: 210px;
    }
    ${media.down('sm')} {
        height: 180px;
        background: transparent;
    }
`