import React, {FunctionComponent} from "react";
import {HeaderLineWrapper} from "./styles";
import { useTranslation } from "react-i18next";


export const HeaderLineComponent: FunctionComponent = () => {
    const { t } = useTranslation();

    return <>
        <HeaderLineWrapper>
            <a href={t('back_link')}>
                <i className="small-arrow-left"></i>
                {t('back_nav')}
            </a>
        </HeaderLineWrapper>
    </>
}
