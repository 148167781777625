import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from "react";
import {HeadDescriptionAreas} from "../../ui/molecules/head-description-areas/head-description-areas";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {WrapperTabsAreas} from "./styles";
import {AreasBreadcrumb} from "../../breadcrumbs/areas";
import { SoloArea } from '../../ui/molecules/solo-area';
import BiContainer from '../../ui/atoms/container/BiContainer';
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import getSlug from "../../../core/router/getSlug";

export const AreasPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const [areas, setAreas] = useState([]);
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/areas/page/${pageid}`)
                .then(response => {
                    setAreas(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
        }
    }, [pageid])

    return <>
        <BiContainer>
            <AreasBreadcrumb />
            <HeadDescriptionAreas
                title={content['header_-_title']}
                content={content['header_-_content']}
            />
            <Tabs>
                <WrapperTabsAreas>
                    <TabList>
                        {Object.keys(areas).map((keyName, i) => (
                            <Tab>{areas[keyName].data.title}</Tab>
                        ))}
                    </TabList>
                </WrapperTabsAreas>
                {Object.keys(areas).map((keyName, i) => (
                    <TabPanel key={i}>
                        <SoloArea
                            id={areas[keyName].id}
                            contact_title={content['contact_title']}
                            contact_content={content['contact_content']}
                            contact_send_to={content['contact_send_to']}
                            contact_pardot_link={content['pardot_link']}
                        />
                    </TabPanel>
                ))}
            </Tabs>
        </BiContainer>
    </>
}
