import React, {FunctionComponent} from "react";
import {WrapperSaleforceHelp, SaleforceHelpDesc, SaleforceHelpItems, SoloItem} from "./styles";
import {ISaleforceHelp} from "./interface";
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from "react-i18next";
import generateLink from "../../../../core/api/assets";

export const SaleforceHelp: FunctionComponent<ISaleforceHelp> = ({title, content,items}) => {
    const { t } = useTranslation();

    return <>
        <WrapperSaleforceHelp>
            <SaleforceHelpDesc>
                <h4>{title}</h4>
                <p>{content}</p>
            </SaleforceHelpDesc>
            <SaleforceHelpItems>
                {Object.keys(items).map((keyName, i) => (
                    <SoloItem key={i}>
                        <img src={generateLink(items[keyName].data.image)} />
                        <p>{ReactHtmlParser(items[keyName].data.content)}</p>
                    </SoloItem>
                ))}
            </SaleforceHelpItems>
        </WrapperSaleforceHelp>
    </>
}
