import styled from "styled-components";
import {media} from "../../../ui/atoms/theme";

export const WrapperSaleforceHelp = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    margin: 160px 0;
`

export const SaleforceHelpDesc = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: table;
    margin: 0 0 80px 0;
    h4{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 30px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        max-width: 586px;
        margin: 0;
    }
    ${media.down('lg')} {
        h4{
            font-size: 30px;
            line-height: 38px;
        }
    }
`

export const SaleforceHelpItems = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 0;
    gap: 50px 20px;
`

export const SoloItem = styled.div`
    flex: 1 0 33%;
    max-width: calc((100% / 3) - 20px);
    img{
        width: 100%;
        display: block;
        margin: 0 0 20px 0;
    }
    h5{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        margin: 0 0 20px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #25282B;
        margin: 0;
    }
    ${media.down('xl')} {
        flex: 1 0 50%;
        max-width: calc((100% / 2) - 20px);
    }
    ${media.down('lg')} {
        flex: 1 0 100%;
        max-width: 100%;
        h5{
            font-size: 22px;
            line-height: 26px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
        }
    }
`