import React, {FunctionComponent} from "react";
import {IconWindow} from "../../atoms/icon-window/icon-window";
import { SecondDescriptionPowerApp } from "../../molecules/second-description-power-app/second-description-power-app";
import {WrapperParthershipmMeans} from "./styles";
import {BIWhatPowerApps} from './interface'

import {useBreakpoints} from "../../atoms/container/hooks";
import ReactHtmlParser from 'react-html-parser';



export const WhatPowerApp: FunctionComponent<BIWhatPowerApps> = ({title, content}) => {
    const {isMedium, isXSmall, isSmall} = useBreakpoints();
    return <>
        <WrapperParthershipmMeans>
            <SecondDescriptionPowerApp 
                title={title}
                content={content}
            />
            {!isXSmall && !isSmall && !isMedium
            ? <IconWindow/> : null
            }
        </WrapperParthershipmMeans>
    </>
}
