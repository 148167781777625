import React, {FunctionComponent, useState} from "react";
import {WrapperSoloCaseStudy, WrapperCaseStudyPopup, WrapperCaseStudyPopupContent, WrapperCaseStudyPopupTitle, WrapperCaseStudyPopupImage, WrapperCaseStudyPopupSection} from "./styles";
import { BISoloCaseStudy } from './interfaces'
import { ActionArrowAtom } from "../../atoms/action-arrow";
import ReactHtmlParser from 'react-html-parser';

export const SoloCaseStudy: FunctionComponent<BISoloCaseStudy> = ({title, content, image, title_popup, content_popup, title_popup_1, content_popup_1, title_popup_2, content_popup_2, title_popup_3, content_popup_3, text_url, link, link_text}) => {
    const [isPopupOpen, setOpenPopup] = useState(false);

    return <>
        <WrapperSoloCaseStudy onClick={() => setOpenPopup(prev => !prev)}>
            <p>{title}</p>
            <span>{ReactHtmlParser(content)}</span>
            <ActionArrowAtom  link={""} text={link_text}/>
        </WrapperSoloCaseStudy>
        {isPopupOpen &&<WrapperCaseStudyPopup>
            <div className="close-icon" onClick={() => setOpenPopup(prev => !prev)}></div>
            <WrapperCaseStudyPopupContent>
                <WrapperCaseStudyPopupTitle>
                    <h6>{title_popup}</h6>
                </WrapperCaseStudyPopupTitle>
                <WrapperCaseStudyPopupSection>
                    <p>{ReactHtmlParser(content_popup)}</p>
                </WrapperCaseStudyPopupSection>
                <WrapperCaseStudyPopupImage>
                    <img src={image} />
                </WrapperCaseStudyPopupImage>
                <WrapperCaseStudyPopupSection>
                    <span>{title_popup_1}</span>
                    <p>{ReactHtmlParser(content_popup_1)}</p>
                </WrapperCaseStudyPopupSection>
                <WrapperCaseStudyPopupSection>
                    <span>{title_popup_2}</span>
                    <p>{ReactHtmlParser(content_popup_2)}</p>
                </WrapperCaseStudyPopupSection>
                <WrapperCaseStudyPopupSection>
                    <span>{title_popup_3}</span>
                    <p>{ReactHtmlParser(content_popup_3)}</p>
                    <ActionArrowAtom  link={link} text={text_url}/>
                </WrapperCaseStudyPopupSection>
            </WrapperCaseStudyPopupContent>
        </WrapperCaseStudyPopup>}
    </>
}