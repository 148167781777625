import styled from "styled-components";
import {media} from "../../atoms/theme";
import IconCheckSVG from "../../../../assets/icons/check.svg";

export const WrapperListItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.up('lg')} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
export const ListOfItem = styled.div`
  padding-bottom: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColorMain};

  ${media.up('md')} {
    max-width: 590px;
  }
`
export const ListItemName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  h4 {
    max-width: 85%;
    font-size: 24px;
    margin: 0;
  }
`
export const CheckIcon = styled.div`
  background-image: url("${IconCheckSVG}");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px;
  margin-right: 20px;
`
export const ContainerListCheck = styled.div`
  color: ${(props) => props.theme.colors.black};
  margin: 60px 0;

  ${media.up('md')} {
    margin: 160px 0;
  }

  h2 {
    margin: 0;
  }
`
