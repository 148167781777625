import React, {FunctionComponent} from "react";
import {WrapperPeople, WrapperPeopleList} from "./styles";
import {SoloPerson} from '../../molecules/solo-person';
import { BIPeople } from "./interfaces"
import ReactHtmlParser from 'react-html-parser';
import generateLink from "../../../../core/api/assets";

export const People: FunctionComponent<BIPeople> = ({people, title, content}) => {

    return <>
        <WrapperPeople>
            <h2>{title}</h2>
            <p className="top-content">{ReactHtmlParser(content)}</p>
            <WrapperPeopleList>
            {Object.keys(people).map((keyName, i) => (
                <SoloPerson key={i}
                    image={people[keyName].data.image ? generateLink(people[keyName].data.image) : ''}
                    image_large={people[keyName].data.image ? generateLink(people[keyName].data.image) : ''}
                    name={people[keyName].data.title}
                    position={people[keyName].data.position}
                    portfolio_title={people[keyName].portfolio_title}
                    portfolio_desc={people[keyName].portfolio_desc}
                    help_title={people[keyName].help_title}
                    help_desc={people[keyName].help_desc}
                />
            ))}
            </WrapperPeopleList>
        </WrapperPeople>
    </>
}
