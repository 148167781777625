import styled from "styled-components";
import ArrowLeft from "../../../../assets/icons/small-left-arrow.svg";

export const HeaderLineWrapper = styled.div`
    width: 100%;
    padding: 0 21px;
    height: 40px;
    background: ${(props) => props.theme.colors.primaryOrange};
    a{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #fff;
        text-decoration: none;
        i.small-arrow-left{
            content: url("${ArrowLeft}");
            margin: 4px 10px -1px 0;
        }
    }
`
