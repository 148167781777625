import React, {FunctionComponent} from "react";
import { WrapperActionArrow } from "./styles";
import { Link } from 'react-router-dom';
import {BIActionArrowProps} from "./interfaces";
export const ActionArrowAtom: FunctionComponent<BIActionArrowProps> = ({text,link, out}) => {
    return <>
        <WrapperActionArrow >
            {link !== '' && out !== true ? <Link to={link} >
                <div className="wrapper">
                    <div className="sliding-arrow"></div>
                </div>
               <span className="text-link">{text}</span>
            </Link> :
            out && out == true ?
            <a href={link} target="_blank">
                <div className="wrapper">
                    <div className="sliding-arrow"></div>
                </div>
                <span className="text-link">{text}</span>
            </a>
            :
            <a>
                <div className="wrapper">
                    <div className="sliding-arrow"></div>
                </div>
                <span className="text-link">{text}</span>
            </a>
            }
        </WrapperActionArrow>
    </>
}
