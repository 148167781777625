import styled from "styled-components";
import {media} from "../theme";

export const Button = styled.div`
  input{
    background: ${(props) => props.theme.colors.primaryOrange};
    border-radius: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s;
    -webkit-appearance: none;
    &:hover{
      background: #000000;
      transition: background 0.2s;
    }
  }
`;
