import styled from "styled-components";
import {media} from "../../atoms/theme";
import CaseStudy1 from "../../../../assets/images/CaseStudy1.png";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

export const WrapperSoloCaseStudy = styled.div`
    flex: 1 0 41%;
    max-width: 49%;
    margin-bottom: 20px;
    background: #EDEFF1;
    padding: 30px;
    p{
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;        
        margin: 0 0 20px 0;
    }
    span{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;    
        padding-bottom: 20px;    
        display: inline-block;
    }
    ${media.down('lg')} {
        flex: 1 0 100%;
        max-width: 100%;
    }
    ${media.down('md')} {
        p{
            font-size: 24px;
            line-height: 30px;
        }
        span{
            display: none;
        }
    }
`

export const WrapperCaseStudyPopup = styled.div`
    position: fixed;
    background: #fff;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .close-icon{
        background-image: url("${CloseIcon}");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        background-size: 64px;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        z-index: 10;
        ${media.down('md')} {
            background-size: 32px;
            width: 32px;
            height: 32px;
            top: 20px;
            right: 20px;
        }
    }
`

export const WrapperCaseStudyPopupContent = styled.div`
    position: relative;
    padding: 90px 220px 90px 620px;
    box-sizing: table;
    display: table;
    ${media.down('xl')} {
        padding-right: 50px;
    }
    ${media.down('lg')} {
        padding: 30px 50px 90px 50px;
    }
    ${media.down('md')} {
        padding: 0 20px 30px 20px;
    }
`

export const WrapperCaseStudyPopupTitle = styled.div`
    width: 100%;
    margin-bottom: 40px;
    h6{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-bottom: 20px;
    }
    span{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }
    ${media.down('md')} {
        h6{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 10px;
        }
        span{
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 30px;
        }
    }
`

export const WrapperCaseStudyPopupImage = styled.div`
    width: 500px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-image: url("${CaseStudy1}");
    ${media.down('lg')} {
        position: relative;
        display: block;
        min-height: auto;
        margin-bottom: 60px;
        width: 100%;
        img{
            width: 100%;
            display: block;
        }
    }
    ${media.down('md')} {
        margin-bottom: 30px;
    }
`

export const WrapperCaseStudyPopupSection = styled.div`
    width: 100%;
    margin-bottom: 40px;
    span{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    ul{
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
        }
    }
    ${media.down('md')} {
        span{
            font-size: 22px;
            line-height: 26px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
        ul{
            li{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
`
