import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {BackgroundSaleforceImage} from "../../ui/molecules/background-saleforce-image";
import BiContainer from "../../ui/atoms/container/BiContainer";
import { HeadDescLogo } from "../../ui/organisms/head-home-desc-logo";
import Person from "../../../assets/images/saleforce/person.jpg";
import {SaleforceMeans} from "../../ui/organisms/saleforce-means/saleforce-means";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { People } from '../../ui/organisms/people/people';
import { SaleforceHelp } from '../../ui/organisms/saleforce-help/saleforce-help';
import { ContactPerson } from '../../ui/organisms/contact-person/contact-person';
import { MicrosoftGala } from '../../ui/organisms/microsoft-gala';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";
import { useLocation } from 'react-router-dom';

export const SaleforcePage: FunctionComponent = () => {
    const location = useLocation();
    const slug = getSlug(location.pathname);
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const [people, setPeople] = useState([]);
    const [helps, setHelps] = useState([]);


    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        let routes_url = `${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`;
        axios.get(`${routes_url}`)
            .then(response => {
                setContent(response.data.data);
                setPageId(response.data.id);
            }) 
            .catch(err => {
                console.log(err);
            })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/helps/page/${pageid}`)
                .then(response => {
                    setHelps(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/people/page/${pageid}`)
                .then(response => {
                    setPeople(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
        }
    }, [pageid])

    return <>
        <BiContainer>
            <HeadDescLogo
                title={content['header_-_title']}
                content={content['header_-_content']}
                link=""
                link_show={false}
                image={generateLink(content['header_-_logo'])}
            />
        </BiContainer>
        <BackgroundSaleforceImage
            banner={generateLink(content['header_-_banner'])}
        />
        <BiContainer>
            <SaleforceMeans
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
                image={generateLink(content['section_1_-_image'])}
            />
            <MicrosoftGala 
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
            />
            <SaleforceHelp
                title={content['section_3_-_title']}
                content={content['section_3_-_content']}
                items={helps}
            />
            <People
                title={content['section_4_-_title']}
                content=""
                people={people}
            />
            <ContactPerson
                title={content['contact_title']}
                place={content['contact_position']}
                content={content['contact_content']}
                name={content['contact_person_name']}
                image={generateLink(content['contact_avatar'])}
                send_to={content['contact_send_to']}
                pardot_link={''}
            />
        </BiContainer>

    </>
}
