import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from "react";
import {HeadPowerApp} from "../../ui/organisms/head-power-app/head-power-app";
import {BackgroundPowerAppImage} from "../../ui/molecules/background-power-app-image";

import {WhatPowerApp} from "../../ui/organisms/what-power-app";
import {ListCheck} from "../../ui/organisms/list-check/list-check";
import {WillPayBack} from "../../ui/organisms/will-pay-back/will-pay-back";
import Contact from "../../ui/organisms/contact";
import {PowerAppBreadcrumb} from "../../breadcrumbs/power-app/power-app";
import BiContainer from "../../ui/atoms/container";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import generateLink from "../../../core/api/assets"
import getSlug from "../../../core/router/getSlug";
import { PopupContact } from '../../ui/molecules/popup-contact';

export const PowerAppsPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [pageid, setPageId] = useState('');
    const [lang, setLang] = useGlobalLang();
    const [benefits, setBenefits] =  useState([]);
    const [departments, setDepartments] =  useState([]);
    const [apps, setApps] =  useState([]);
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/benefits/page/${pageid}`)
                .then(response => {
                    setBenefits(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/departments/page/${pageid}`)
                .then(response => {
                    setDepartments(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/examples/page/${pageid}`)
                .then(response => {
                    setApps(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
        }
    }, [pageid])

    console.log(content['section_1_-_content'])

    return <>
            <BiContainer>
                    <PowerAppBreadcrumb/>
                    <HeadPowerApp
                        title={content['header_-_title']}
                        content={content['header_-_content']}
                    />
            </BiContainer>
            <BackgroundPowerAppImage
                image={content['header_-_banner'] ? generateLink(content['header_-_banner']) : ''}
            />
            <BiContainer>
            <WhatPowerApp
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
            />
            <ListCheck title={content['section_2_-_title']} items={benefits}/>
            <WillPayBack
                title={content['section_3_-_title']}
                value_box_1={content['value_box_1_-_title']}
                content_box_1={content['value_box_1_-_undertitle']}
                value_box_2={content['value_box_2_-_title']}
                content_box_2={content['value_box_2_-_undertitle']}
                value_box_3={content['value_box_3_-_title']}
                content_box_3={content['value_box_3_-_undertitle']}
            />
            <ListCheck title={content['section_4_-_title']} items={departments}/>
            <ListCheck title={content['section_5_-_title']} items={apps}/>
            <PopupContact
                title={content['contact_title']}
                content={content['contact_content']}
                text_link={t('contact')}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
            </BiContainer>
    </>
}
