import { HomePage } from '../../components/pages/home';
import { GalaPage } from '../../components/pages/gala';
import { ServicePage } from '../../components/pages/services/service';
import { SolutionAssessmentPage } from '../../components/pages/services/solution-assessment';
import { SolutionConceptPage } from '../../components/pages/services/solution-concept';
import { ProjectDeliveryPage } from '../../components/pages/services/project-delivery';
import { MaintenanceAndFurtherDevelopmentPage } from '../../components/pages/services/maintenance-and-further-development';
import { PowerAppsPage } from "../../components/pages/power-apps";
import { AreasPage } from "../../components/pages/areas/areas";
import {SaleforcePage} from "../../components/pages/saleforce/saleforce";

const getPageComponent = (component) => {
    if(component == 'aws_home'){
        return HomePage;
    }else if(component == 'microsoft_about_relationship'){
        return GalaPage;
    }else if(component == 'microsoft_services'){
        return ServicePage;
    }else if(component == 'microsoft_solution_assessment'){
        return SolutionAssessmentPage;
    }else if(component == 'microsoft_solution_concept'){
        return SolutionConceptPage;
    }else if(component == 'microsoft_project_delivery'){
        return ProjectDeliveryPage;
    }else if(component == 'microsoft_maintenance_and_further_development'){
        return MaintenanceAndFurtherDevelopmentPage;
    }else if(component == 'microsoft_power_apps'){
        return PowerAppsPage;
    }else if(component == 'microsoft_areas'){
        return AreasPage;
    }else if(component == 'salesforce_home'){
        return SaleforcePage;
    }
}

export default getPageComponent;