import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerMicrosoftGala = styled.div`
    width: 100%;
    box-sising: border-box;
    display: table;
    margin: 100px 0;

    h2{
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
    }
    
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
    }

    ${media.up('md')} {
        h2{
            font-size: 46px;
            line-height: 56px;
            float: left;
            max-width: 327px !important;
            margin-right: 80px;
        }
        h2, p{
            max-width: 692px;
        }
    }
`
