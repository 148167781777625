import React, {FunctionComponent} from "react";
import {WrapperBorder, WrapperHeadPowerApp } from "./styles";
import { BIHeadPowerApps } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const HeadPowerApp: FunctionComponent<BIHeadPowerApps> = ({title, content}) => {
    return <>
        <WrapperHeadPowerApp>
            <div>
                <h1>
                    {title}
                </h1>
                <p>
                    {ReactHtmlParser(content)}
                </p>
            </div>

            <WrapperBorder/>
        </WrapperHeadPowerApp>

    </>
}
