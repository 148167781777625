import styled from "styled-components";
import {media} from "../../atoms/theme";
import BackgroundImgGalaMobile from "../../../../assets/images/background-img-gala-mobile.png";
import BackgroundImgGala from "../../../../assets/images/background-Img-home.jpg";

export const WrapperBackgroundGalaImage = styled.div`
    background-image: url("${BackgroundImgGala}");
    background-repeat: no-repeat;
    width: 50%;
    height: 250px;
    background-size: cover;
    position: relative;
    float: right;

    ${media.up('xl')} {
        height: 400px;
        width: calc(100% - 528px);
        background-image: url("${BackgroundImgGala}");
    }
`
