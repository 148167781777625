import React, {FunctionComponent} from "react";
import {WrapperDescriptionAreas} from "./styles";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';
import { IHeadAreas } from "./interface";

export const HeadDescriptionAreas: FunctionComponent<IHeadAreas> = ({title, content}) => {
    const { t } = useTranslation();

    return <>
        <WrapperDescriptionAreas>
            <h1>{title}</h1>
            <p>{ReactHtmlParser(content)}</p>
        </WrapperDescriptionAreas>
    </>
}
