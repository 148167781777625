import styled from "styled-components";
import TechServices from "../../../../assets/icons/tech-icon.svg";
import {media} from "../../atoms/theme";
import { IThumb } from "./interfaces";
import generateLink from "../../../../core/api/assets";

export const IconServices = styled.div<IThumb>`
  background-image: url("${(props) => props.thumb ? generateLink(props.thumb) : TechServices}");
  background-repeat: no-repeat;
  width: 109px;
  height: 125px;
  background-size: 108px;
  margin-bottom: 20px;

  ${media.up('lg')} {
    margin-bottom: 0;
  }

`
export const WrapperItemService = styled.div`
  margin-bottom: 40px;

  ${media.up('lg')} {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
`
export const DescItemService = styled.div`

  h2 {
    margin-top: 0;
  }

  ${media.up('lg')} {
    max-width: 70%;
    padding-left: 120px;
    margin-bottom: 120px;
  }
`
export const ContainerItemServices = styled.div`
  margin-bottom: 100px;
`
