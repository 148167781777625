import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperBorder = styled.div`
  width: 100%;
  border-top: 6px solid ${(props) => props.theme.colors.primaryOrange};
  border-right: 6px solid ${(props) => props.theme.colors.primaryOrange};

  ${media.up('md')} {
    width: 50%;
    height: 235px;
    position: absolute;
    top: 30px;
    right: 0;
  }

  ${media.up('lg')} {
    width: 65%;
  }
`

export const WrapperHeadPowerApp = styled.div`
  position: relative;
  margin-bottom: 70px;
  ${media.up('lg')} {

    p {
      max-width: 591px;
      margin-top: 36px;
      margin-bottom: 60px;
    }
  }
`
