import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../../styles'
import { BIGalaBreadcrumb } from "../../interfaces"

export const ServiceBreadcrumb: FunctionComponent<BIGalaBreadcrumb> = ({title, slug}) => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Partnerstwo AWS</Link>
            <ArrowRightIcon />
            <Link to="/services" onClick={() => { history.push(slug);}}>{title}</Link>
        </WrapperBreadcrumb>
    </>
}
