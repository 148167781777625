import styled from "styled-components";
import {media} from "../../atoms/theme";


export const WrapperDescriptionGala = styled.div`
  width: 100%;
  max-width: 100%;

  h1 {
    margin-top: 0;
  }

  p {
    max-width: 80%;
  }

  ${media.up('lg')} {
    p {
      max-width: 50%;
    }
  }
`
