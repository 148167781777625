import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../../styles'
import { BIGalaBreadcrumb } from "../../interfaces"
import { useTranslation } from "react-i18next";

export const SoloServiceBreadcrumb: FunctionComponent<BIGalaBreadcrumb> = ({title, slug}) => {
    const {t} = useTranslation();
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Microsoft Partnership</Link>
            <ArrowRightIcon />
            <Link to="/services" onClick={() => { history.push(slug);}}>{t('services')}</Link>
            {title &&<ArrowRightIcon />}
            {title &&<Link to={`/services/${slug}`} onClick={() => { history.push(`/services/${slug}`);}}>{title}</Link>}
        </WrapperBreadcrumb>
    </>
}
