import React, {FunctionComponent} from "react";
import {ContainerMicrosoftGala } from "./styles";
import {MicrosoftGalaContent} from "../../molecules/microsoft-gala-content";
import { useTranslation } from "react-i18next";
import { BIMicrosoftGala } from "./interfaces"

export const MicrosoftGala: FunctionComponent<BIMicrosoftGala> = ({title, content}) => {
    const { t } = useTranslation();

    return <>
        <ContainerMicrosoftGala >
            <h2>{title}</h2>
            <MicrosoftGalaContent content={content} />
        </ContainerMicrosoftGala>
    </>
}