import React, {FunctionComponent} from "react";
import {WrapperServiceListImg, Content, Image, ContentIn} from "./styles";
import {BIServiceListImgProps} from "./interfaces";
import ReactHtmlParser from 'react-html-parser';
import generateLink from "../../../../core/api/assets"

export const ServiceListImg: FunctionComponent<BIServiceListImgProps> = ({items}) => {
    return <>
        <WrapperServiceListImg>
        {Object.keys(items).map((keyName, i) => (
            <div className="solo-article">
                <Image>
                    <img src={generateLink(items[keyName].data['image'])} />
                </Image>
                <Content>
                    <ContentIn>
                        <h3>{items[keyName].data['title']}</h3>
                        <p>{ReactHtmlParser(items[keyName].data['content'])}</p>
                    </ContentIn>
                </Content>
            </div>
        ))}
        </WrapperServiceListImg>
    </>
}
