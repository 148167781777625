import React, {FunctionComponent} from "react";
import {footerItems} from './footerItems';
import BiContainer from "../../atoms/container";
import {LogoAtom} from "../../atoms/logo/logo-atom";
import {SocialFooterMolecul} from "../../molecules/social-footer";
import {Footer, FooterSocialMedia, FooterSubTitle, FooterTitle, FooterWrapperRow} from "./styles";


export const FooterComponent: FunctionComponent = () => {
    return <>
        <BiContainer>
            <FooterSocialMedia>
                <LogoAtom/>
                <SocialFooterMolecul/>
            </FooterSocialMedia>
        </BiContainer>
    </>
}
