import styled from "styled-components";
import {media} from "../../atoms/theme";
import MicrosoftImage from "../../../../assets/icons/microsoft-icon-home.png";

export const WrapperLogoMicrosoft = styled.div`
  width: 100%;
  margin-top: 20px;
  border-top: 6px solid ${(props) => props.theme.colors.primaryOrange};
  border-right: 6px solid ${(props) => props.theme.colors.primaryOrange};
  img{
    width: 200px;
    margin-bottom: 149px;
    margin-top: 149px;
    display: none;
    float: right;
    margin-right: 30px;
  }
  ${media.up('sm')} {
    img{
      display: inherit;
    }
  }
  ${media.up('lg')} {
    margin-top: 36px;
    margin-left: 40px;
    img{
      width: 243px;
      margin-right: 272px;
    }
  }

  ${media.down('md')} {
    position: relative;
    margin-left: -157px;
    max-width: 157px;
    min-height: 375px;
    right: 23px;
    img{
      margin-right: 100px;
    }
  }
`
export const LogoMicrosoftParther = styled.div`
  background-image: url("${MicrosoftImage}");
  background-repeat: no-repeat;
  width: 200px;
  height: 150px;
  background-size: 200px;
  margin-bottom: 149px;
  margin-top: 149px;
  display: none;
  float: right;
  margin-right: 30px;

  ${media.up('sm')} {
    display: inherit;
  }

  ${media.up('md')} {
    margin-right: 100px;
  }

  ${media.up('lg')} {
    width: 243px;
    height: 183px;
    background-size: 243px;
    float: right;
    margin-right: 272px;
  }
`
