import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import ListOfServices from "../../../ui/organisms/list-of-services";
import Contact from "../../../ui/organisms/contact"
import {TitleServices} from "./styles";
import {WrapperDescService} from "./styles";
import {ServiceBreadcrumb} from "../../../breadcrumbs/services/service"
import BiContainer from "../../../ui/atoms/container/BiContainer";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../../ui/molecules/lang-header/useGlobalLang'
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom';
import getSlug from "../../../../core/router/getSlug";
import { PopupContact } from '../../../ui/molecules/popup-contact';

export const ServicePage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [url, setUrl] = useState("");
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setUrl(response.data.uri);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer>
            <ServiceBreadcrumb
                title={content['title']}
                slug={url}
            />
            <WrapperDescService>
                <TitleServices>
                    <h1>{content['header_-_title']}</h1>
                </TitleServices>
                <p>{content['header_-_content']}</p>
            </WrapperDescService>
            <ListOfServices
                icon_service1={content['section_1_-_icon']}
                icon_service2={content['service_2_-_icon']}
                icon_service3={content['service_3_-_icon']}
                icon_service4={content['service_4_-_icon']}
                title_service1={content['service_1_-_title']}
                content_service1={`<p>${content['service_1_-_content']}</p>`}
                slug_service1={content['service_1_-_link']}
                title_url_service1={content['service_1_-_link_title']}
                title_service2={content['service_2_-_title']}
                content_service2={`<p>${content['service_2_-_content']}</p>`}
                slug_service2={content['service_2_-_link']}
                title_url_service2={content['service_2_-_link_title']}
                title_service3={content['service_3_-_title']}
                content_service3={`<p>${content['service_3_-_content']}</p>`}
                slug_service3={content['service_3_-_link']}
                title_url_service3={content['service_3_-_link_title']}
                title_service4={content['service_4_-_title']}
                content_service4={`<p>${content['service_4_-_content']}</p>`}
                slug_service4={content['service_4_-_link']}
                title_url_service4={content['service_4_-_link_title']}
            />
            <PopupContact
                title={content['contact_title']}
                content={content['contact_content']}
                text_link={t('contact')}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>
    </>
}
