import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import {WrapperSoloSolution, WrapperSolutionPopup, WrapperSolutionPopupContent, WrapperSolutionPopupTitle, WrapperSolutionPopupImage, WrapperSolutionPopupSection, PeopleSection} from "./styles";
import { BISoloSolution } from './interfaces'
import ReactHtmlParser from 'react-html-parser';
import { SoloPerson } from "../solo-person";
import generateLink from "../../../../core/api/assets";
import { useGlobalLang } from '../lang-header/useGlobalLang';

export const SoloSolution: FunctionComponent<BISoloSolution> = ({id, title, content, points, title_popup, content_popup, image, title_section_1, list_section_1, title_section_2 }) => {
    const [isPopupOpen, setOpenPopup] = useState(false);
    const [people, setPeople] = useState([]);
    const [lang, setLang] = useGlobalLang();

    console.log(id);

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/people/solution/${id}`)
        .then(response => {
            setPeople(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <WrapperSoloSolution>
            <h5>{title}</h5>
            <p>{content}</p>
            <ul>
                {points.map(point => 
                    <li>{point}</li>
                )}
            </ul>
            <a onClick={() => setOpenPopup(prev => !prev)}>More about team</a>
        </WrapperSoloSolution>
        {isPopupOpen &&<WrapperSolutionPopup>
            <div className="close-icon" onClick={() => setOpenPopup(prev => !prev)}></div>
            <WrapperSolutionPopupContent>
                <WrapperSolutionPopupTitle>
                    <h6>{title_popup}</h6>
                </WrapperSolutionPopupTitle>
                <WrapperSolutionPopupSection>
                    <p>{ReactHtmlParser(content_popup)}</p>
                </WrapperSolutionPopupSection>
                <WrapperSolutionPopupImage>
                    <img src={image} />
                </WrapperSolutionPopupImage>
                <WrapperSolutionPopupSection>
                    <span>{title_section_1}</span>
                    <ul>
                        {list_section_1.map(point => 
                            <li>{point}</li>
                        )}
                    </ul>
                </WrapperSolutionPopupSection>
                <WrapperSolutionPopupSection>
                    <span>{title_section_2}</span>
                    <PeopleSection>
                        {Object.keys(people).map((keyName, i) => (
                            <SoloPerson key={i}
                                image={people[keyName].data.image ? generateLink(people[keyName].data.image) : ''}
                                image_large={people[keyName].data.image ? generateLink(people[keyName].data.image) : ''}
                                name={people[keyName].data.title}
                                position={people[keyName].data.position}
                                portfolio_title={people[keyName].portfolio_title}
                                portfolio_desc={people[keyName].portfolio_desc}
                                help_title={people[keyName].help_title}
                                help_desc={people[keyName].help_desc}
                            />
                        ))}
                    </PeopleSection>
                </WrapperSolutionPopupSection>
            </WrapperSolutionPopupContent>
        </WrapperSolutionPopup>}
    </>
}