import styled from "styled-components";
import ArrowDash from "../../../../assets/icons/arrow-dash.svg";

export const WrapperActionArrow = styled.div`
  display: flex;
  flex-direction: row;

  a {
    cursor: pointer;
    text-decoration: unset;
    color: black;
    font-weight: 600;
    .wrapper{
      width: 16px;
      height: 16px;
      margin: 4.5px 5px 4.5px 0;
      overflow: hidden;
      float: left;
      .sliding-arrow{
        background: url("${ArrowDash}") no-repeat;
        height: 16px;
        width: 32px;
        transform: translate3d(-16px, 0, 0);
        transition: transform 0.4s;
      }
    }

    &:hover {
      color: ${(props) => props.theme.colors.primaryOrange};
      .wrapper{
        .sliding-arrow{
          transform: translate3d(0, 0, 0);
          transition: transform 0.4s;
        }
      }
    }
  }
`