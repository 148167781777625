import React, {FunctionComponent} from "react";
import {LogoHeader} from "../../atoms/logo-header"
import {LinksHeader} from "../../molecules/links-header"
import {MobileHeader} from "../../molecules/mobile-header"
import {ButtonHeader} from "../../atoms/button-header"
import {LangHeader} from "../../molecules/lang-header"
import {HeaderWrapper, HeaderWrapperRight} from "./styles";


export const HeaderComponent: FunctionComponent = () => {
    return <>
        <HeaderWrapper>
            <LogoHeader />
            <LinksHeader />
            <MobileHeader />
            <HeaderWrapperRight>
                <ButtonHeader />
                <LangHeader />
            </HeaderWrapperRight>
        </HeaderWrapper>
    </>
}
