import React, {FunctionComponent, useEffect} from "react";
import {ContainerCompetence, IconSaleforce, WrapperCertificatesDesc, WrapperCompetenceDesc, WrapperImgCompetence, WrapperItemsIcons} from "./styles";
import { ICompetenciesList } from './interface'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export const CompetenceList: FunctionComponent<ICompetenciesList> = ({title, content, items}) => {

    useEffect(() => {
        const icons = document.getElementsByClassName('item-icon');
        let icon_height = 0;
        for(var j=0; j < icons.length; j++)
            if(icon_height < icons[j].clientHeight) icon_height = icons[j].clientHeight;
        for(var j=0; j < icons.length; j++)
            icons[j].setAttribute('style',`height: ${icon_height}px`)
    }, [items])

    return <>
        <ContainerCompetence>
            <WrapperCompetenceDesc>
                <div className="content">
                    <h2>{title}</h2>
                    <p>{ReactHtmlParser(content)}</p>
                </div>

            </WrapperCompetenceDesc>
            <WrapperItemsIcons>
                {Object.keys(items).map((keyName, i) => (
                    <div className="item-icon">
                        <IconSaleforce thumb={items[keyName].data['thumb']}/>
                        <p>{items[keyName].data['title']}</p>
                    </div>
                ))}
            </WrapperItemsIcons>
        </ContainerCompetence>
    </>

}
