import React, {FunctionComponent} from "react";
import {WrapperMicrosoftGalaContent} from "./styles";
import { useTranslation } from "react-i18next";
import { BIMicrosoftGalaContent } from "./interfaces"
import ReactHtmlParser from 'react-html-parser';
export const MicrosoftGalaContent: FunctionComponent<BIMicrosoftGalaContent> = ({content}) => {
    const { t } = useTranslation();

    return <>
        <WrapperMicrosoftGalaContent>
            <p>{ReactHtmlParser(content)}</p>
        </WrapperMicrosoftGalaContent>
    </>
}