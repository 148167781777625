import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {media} from "../../atoms/theme";
import {IBackgroundPowerApps} from './interface'


export const BackgroundPowerAppImage: FunctionComponent<IBackgroundPowerApps> = ({image}) => {

    const ImgBackgroundFullPowerApp = styled.div`
    margin-top: 60px;
    background-image: url("${image}");
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
    background-size: cover;
    position: relative;
    z-index: 10;
    margin-bottom: 40px;


    ${media.up('md')} {
        margin-top: 0px;
        background-image: url("${image}");
        background-position: 50% 50%;
        height: 400px;
        margin-bottom: 160px;
    }

    ${media.down('sm')} {
        background-size: auto;
        margin: 0 16px;
    }
    `

    return <>
        <ImgBackgroundFullPowerApp />

    </>
}
