import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperSaleforceMeans = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 180px 0;
  position: relative;
`

export const SaleforceMeansContent = styled.div`
    max-width: 315px;
    float: left;
    h2{
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #25282B;
        margin: 0 0 30px 0;
        max-width: 190px;
    }
    p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #25282B;
        margin: 0;
    }

    ${media.up('lg')} {
        max-width: 692px;
        margin-right: 105px;
        h2{
            font-size: 46px;
            line-height: 56px;
            max-width: 550px;
        }
        p{
            font-size: 18px;
            line-height: 26px;
        }
    }
`

export const SaleforceMeansImage = styled.div`
    position: absolute;
    width: 100px;
    right: 0;
    img{
        width: 100%;
    }

    ${media.up('md')} {
        width: 350px;
    }
    ${media.up('xl')} {
        width: 450px;
    }
`