import React, {FunctionComponent} from "react";
import {PowerAppDescription} from "./styles";
import ReactHtmlParser from 'react-html-parser';
import {ISecondDesc} from './interface'

export const SecondDescriptionPowerApp: FunctionComponent<ISecondDesc> = ({title, content}) => {
    return <>
        <PowerAppDescription>
            <h2>{title}</h2>
            {ReactHtmlParser(content)}
        </PowerAppDescription>
    </>
}
