import axios from 'axios';
import React, {FunctionComponent, useEffect, useState, useRef} from "react";
import {WrapperLinksHeader} from './styles';
import { Link, useHistory } from 'react-router-dom';
import useOutsideClick from '../../organisms/header/useOutsideClick';
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../lang-header/useGlobalLang'

export const LinksHeader: FunctionComponent = () => {
    const history = useHistory();
    const [isNavItemOpen, setOpenNavItem] = useState(false);
    const [routes, setRoutes] = useState<string[]>([]);
    const dropdownRef = useRef<HTMLHeadingElement>(null);
    const { t } = useTranslation();
    const [lang, setLang] = useGlobalLang();

    useOutsideClick(dropdownRef, () => {
      if(isNavItemOpen !== false){
        setOpenNavItem(prev => !prev)
      }
    });

    const getBackendRoutes = (lang) => {
      const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE, REACT_APP_TOP_NAV } = process.env;
      let routes_url = `${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/navigation/${REACT_APP_TOP_NAV}/tree`;
      axios.get(`${routes_url}`)
      .then(response => {
        setRoutes(response.data[0]);
      }) 
      .catch(err => {
        console.log(err);
      })
    };

    useEffect(() => {
      getBackendRoutes(lang);
    }, [lang])

    console.log(routes);

    return <>
        <WrapperLinksHeader>
          {routes.map(route => (
            <ul key={route['id']}>
              {route['children'] && route['children'].length > 0 ?
              <li onClick={() => setOpenNavItem(prev => !prev)}>
                <a>
                    {route['title']}
                    <i className="dropdown-icon"></i>
                </a>
                {isNavItemOpen && <div ref={dropdownRef} className="dropdown-list">
                    <Link className="dropdown-list-title" to={`${route['slug']}`} onClick={() => { history.push(route['slug']);}}>Overview</Link>
                    {route['children'].map((routeChildren) =>
                        <Link to={`${routeChildren['slug']}`} onClick={() => { history.push(`${routeChildren['slug']}`);}}>{routeChildren['title']}</Link>
                    )}
                </div>}
              </li>
              :<li>
                <Link to={`${route['slug']}`} onClick={() => { history.push(route['slug']);}}>{route['title']}</Link>
              </li>}
            </ul>
          ))}
        </WrapperLinksHeader>
    </>
}
