import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperPeople = styled.div`
  width: 100%;
  margin-bottom: 100px;
  h1{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left; 
    margin: 30px 0 0 0;
  }
  p.top-content{
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    max-width: 895px;
    margin: 0 0 80px 0;
  }
`

export const WrapperPeopleList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 60px;
  gap: 20px;
`
