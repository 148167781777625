import React, {FunctionComponent} from "react";
import {WrapperBackgroundGala} from "./styles";
import {BackgroundGalaLogo} from "../../molecules/background-gala-logo";
import {BackgroundGalaImage} from "../../molecules/background-gala-image";

export const BackgroundGala: FunctionComponent = () => {
    return <>
        <WrapperBackgroundGala>
            <BackgroundGalaLogo />
            <BackgroundGalaImage />
        </WrapperBackgroundGala>
    </>
}