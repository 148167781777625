import React, {FunctionComponent} from "react";
import { WrapperDescriptionGala } from "./styles";
import { useTranslation } from "react-i18next";
import { BIGDescGala } from "./interfaces"

export const HeadDescriptionGala: FunctionComponent<BIGDescGala> = ({title}) => {
    const { t } = useTranslation();

    return <>
        <WrapperDescriptionGala>
            <h1>{title}</h1>
        </WrapperDescriptionGala>
    </>
}
