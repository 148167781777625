import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from "react";
import {BackgroundHome} from "../../ui/atoms/background-home/background-home";
import {HeadDescLogo} from "../../ui/organisms/head-home-desc-logo/head-desc-logo";
import BiContainer from "../../ui/atoms/container";

import {ParthershipmMeans} from "../../ui/organisms/parthership-means/parthership-means";
import {NewsAboutPartnership} from "../../ui/organisms/news-about-partnership/news-about-partnership";
import {WhatCanDo} from "../../ui/organisms/what-can-do/what-can-do";
import Areas from "../../ui/organisms/areas/inedx";
import Contact from "../../ui/organisms/contact";
import People from "../../ui/organisms/people";
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import getSlug from "../../../core/router/getSlug";
import { PopupContact } from '../../ui/molecules/popup-contact';
import { CompetenceList } from '../../ui/organisms/competence-list/competence-list';
import { FireList } from '../../ui/molecules/fire-list/fire-list';
import { Certifications } from '../../ui/molecules/certifications/certifications';

export const HomePage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [people, setPeople] = useState([]);
    const [competencies, setCompetencies] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [fires, setFires] = useState([]);
    const [pageid, setPageId] = useState('');
    const [news, setNews] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const slug = getSlug(location.pathname);

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/competencies/page/${pageid}`)
                .then(response => {
                    setCompetencies(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/fire_list/page/${pageid}`)
                .then(response => {
                    setFires(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/certifications/page/${pageid}`)
                .then(response => {
                    setCertifications(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/news/page/${pageid}`)
                .then(response => {
                    setNews(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/people/page/${pageid}`)
                .then(response => {
                    setPeople(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
        }
    }, [pageid])

    return <>
        <BiContainer>
            <HeadDescLogo
                title={content['header_-_title']}
                content={`<p>${content['header_-_content']}</p>`}
                link={content['header_-_link']}
                link_show={true}
                image={content['header_-_logo']}
            />
        </BiContainer>
        <BackgroundHome/>
        <BiContainer>
            <CompetenceList
                title={content['section_1_-_title']}
                content={content['section_1_-_content']}
                items={competencies}
            />
            <Certifications
                title={content['section_2_-_title']}
                content={content['section_2_-_content']}
                items={certifications}
            />
            <FireList
                title={content['section_3_-_title']}
                items={fires}
            />
            <People
                title={content['section_4_-_title']}
                content={content['section_4_-_content']}
                people={people}
            />
            <PopupContact
                title={content['contact_title']}
                content={content['contact_content']}
                text_link={t('contact')}
                send_to={content['contact_send_to']}
                pardot_link={content['pardot_link']}
            />
        </BiContainer>

    </>
}
