import styled from "styled-components";
import {media} from "../../atoms/theme";
import Solution1 from "../../../../assets/images/Solution1.png";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

export const WrapperSoloSolution = styled.div`
    width: calc(50% - 10px);
    min-height: 627px;
    background: #EDEFF1;
    padding: 30px;
    display: table;
    box-sizing: border-box;
    border-radius: 5px;
    h5{
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 20px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 0 0 20px 0;
    }
    ul{
        width: 100%;
        margin: 0;
        padding-left: 20px;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
            margin: 0 0 20px 0;
        }
    }
    a{
        display: block;
        float: right;
        padding: 19px 26px;
        background: #FF5A00;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: #ffffff;
    }
    ${media.down('lg')} {
        width: 100%;
        min-height: auto;
        h5{
            font-size: 22px;
            line-height: 30px;
        }
        p, ul li, a{
            font-size: 14px;
            line-height: 22px;
        }
        ul li{
            margin-bottom: 10px;
        }
        a{
            padding: 11.5px 19px;
        }
    }

`

export const WrapperSolutionPopup = styled.div`
    position: fixed;
    background: #fff;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .close-icon{
        background-image: url("${CloseIcon}");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        background-size: 64px;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        z-index: 10;
        ${media.down('md')} {
            background-size: 32px;
            width: 32px;
            height: 32px;
            top: 20px;
            right: 20px;
        }
    }
`

export const WrapperSolutionPopupContent = styled.div`
    position: relative;
    padding: 90px 220px 90px 620px;
    box-sizing: table;
    display: table;
    ${media.down('xl')} {
        padding-right: 50px;
    }
    ${media.down('lg')} {
        padding: 30px 50px 90px 50px;
    }
    ${media.down('md')} {
        padding: 0 20px 30px 20px;
    }
`

export const WrapperSolutionPopupTitle = styled.div`
    width: 100%;
    margin-bottom: 51px;
    h6{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 96px 0;
    }
    span{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }
    ${media.down('md')} {
        h6{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 10px;
        }
        span{
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 30px;
        }
    }
`

export const WrapperSolutionPopupImage = styled.div`
    width: 500px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-image: url("${Solution1}");
    img{
        display: none;
    }
    ${media.down('lg')} {
        position: relative;
        display: block;
        min-height: auto;
        margin-bottom: 60px;
        width: 100%;
        img{
            width: 100%;
            display: block;
        }
    }
    ${media.down('md')} {
        margin-bottom: 30px;
    }
`

export const WrapperSolutionPopupSection = styled.div`
    width: 100%;
    margin-bottom: 48px;
    span{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    ul{
        padding-left: 20px;
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
        }
    }
    ${media.down('md')} {
        span{
            font-size: 22px;
            line-height: 26px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
        ul{
            li{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
`

export const PeopleSection = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 60px;
    gap: 20px;
`