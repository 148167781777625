import styled from "styled-components";
import {media} from "../theme";
import RectangleWindow from "../../../../assets/icons/rectangle-window.svg";
import RectangleWindowMobile from "../../../../assets/icons/rectangle-window-mobile.svg";

export const WrapperIconWindow = styled.div`
  position: absolute;
  right: 20px;

  ${media.up('sm')} {
    position: relative;
  }
`
export const IconRectangleWindow = styled.div`
  background-image: url("${RectangleWindowMobile}");
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  background-size: 64px;

  ${media.up('sm')} {
    background-image: url("${RectangleWindow}");
    width: 160px;
    height: 160px;
    background-size: 160px;
  }

  ${media.up('lg')} {
    margin-right: 100px;
    width: 260px;
    height: 260px;
    background-size: 260px;
  }
`
