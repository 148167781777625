import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperMicrosoftGalaContent = styled.div`
    width: 100%;
    box-sising: border-box;
    display: table;

    p{
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        margin-bottom: 20px;
    }

    ${media.up('md')} {
        float: left;
        width: calc(100% - 407px);
    }
`
