import React from 'react';
import { ThemeProvider } from 'styled-components';
import {Theme} from "./components/ui/atoms/theme";
import GlobalStyle from './components/ui/atoms/theme/global-style';
import {BaseLayout} from "./components/templates/layout";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
      <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <BaseLayout />
      </ThemeProvider>
  );
}

export default App;
