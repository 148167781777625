import React, {FunctionComponent, useState, useRef, useCallback, useEffect} from "react";
import {WrapperLangHeader} from './styles';
import {ArrowIcon} from '../../atoms/arrow-icon'
import useOutsideClick from '../../organisms/header/useOutsideClick';
import i18next from "i18next";
import cookies from 'js-cookie';
import { useGlobalLang } from './useGlobalLang'


export const LangHeader: FunctionComponent = () => {
    const [isNavItemOpen, setOpenNavItem] = useState(false);
    const dropdownRef = useRef<HTMLHeadingElement>(null);
    const languages = [
        /*{
            code: 'pl',
            name: 'pl',
            country_code: 'pl'
        },*/
        {
            code: 'pl',
            name: 'pl',
            country_code: 'pl'
        },
    ]
    const [lang, setLang] = useGlobalLang();

    useOutsideClick(dropdownRef, () => {
        if(isNavItemOpen !== false){
          setOpenNavItem(prev => !prev)
        }
    });
    
    const changeLang = (name, code) => {
        setLang(name)
        i18next.changeLanguage(code)
    }

    return <>
        <WrapperLangHeader onClick={() => setOpenNavItem(prev => !prev)}>
            <p>{lang}</p>
            <ArrowIcon />
            {isNavItemOpen && <div ref={dropdownRef} className="dropdown-list">
                <ul>
                    {languages.map(({ code, name, country_code}) => (
                        <li key={country_code}>
                            <a onClick={() => changeLang(name, code)}>{name}</a>
                        </li>
                    ))}
                </ul>
            </div>}
        </WrapperLangHeader>
    </>
}