import React, {FunctionComponent} from "react";
import {WrapperCaseStudy, WrapperCaseStudyList} from "./styles";
import { SoloCaseStudy } from "../../molecules/solo-case-study";
import {BICaseStudy} from './interface'
import { useTranslation } from "react-i18next";

export const CaseStudy: FunctionComponent<BICaseStudy> = ({case_study, title}) => {
    const { t } = useTranslation();

    return <>
        <WrapperCaseStudy>
            <h2>{title}</h2>
            <WrapperCaseStudyList>
                {Object.keys(case_study).map((keyName, i) => (
                    <SoloCaseStudy
                        title={case_study[keyName].data.title}
                        content={case_study[keyName].data.content}
                        title_popup={case_study[keyName].data['popup_-_title']}
                        content_popup={case_study[keyName].data['popup_-_content']}
                        image={case_study[keyName].data['popup_-_image']}
                        title_popup_1={case_study[keyName].data['popup_section_1_-_title']}
                        content_popup_1={case_study[keyName].data['popup_section_1_-_content']}
                        title_popup_2={case_study[keyName].data['popup_section_2_-_title']}
                        content_popup_2={case_study[keyName].data['popup_section_2_-_content']}
                        title_popup_3={case_study[keyName].data['popup_section_3_-_title']}
                        content_popup_3={case_study[keyName].data['popup_section_3_-_content']}
                        text_url={case_study[keyName].data['popup_section_-_link_title']}
                        link={case_study[keyName].data['popup_-_link']}
                        link_text={t('see_more')}
                    />
                ))}
            </WrapperCaseStudyList>
        </WrapperCaseStudy>
    </>
}
