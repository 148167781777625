import styled from "styled-components";
import {media} from "../../atoms/theme";
import PersonPopup1 from "../../../../assets/images/PersonPopup1.png";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

export const WrapperSoloPerson = styled.div`
    flex: 1 0 21%;
    max-width: 24%;
    margin-bottom: 40px;
    img{
        width: 100%;
    }
    p{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        margin: 40px 0 5px 0;
    }
    span{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    a{
        margin-top: 10px;
        display: none;
    }
    ${media.down('xl')} {
        flex: 1 0 29%;
        max-width: 31%;
    }
    ${media.down('lg')} {
        flex: 1 0 46%;
        max-width: 46%;
    }
    ${media.down('md')} {
        flex: 1 0 100%;
        max-width: 100%;
        img{
            max-width: 120px;
            float: left;
            margin-right: 20px;
        }
        p{
            margin: 5px 0 10px 0;
            font-size: 22px;
            line-height: 26px;
        }
        span{
            font-size: 14px;
            line-height: 20px;
        }
        a{
            display: inline;
        }
    }
`

export const WrapperPersonPopup = styled.div`
    position: fixed;
    background: #fff;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .close-icon{
        background-image: url("${CloseIcon}");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        background-size: 64px;
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
        z-index: 10;
        ${media.down('md')} {
            background-size: 32px;
            width: 32px;
            height: 32px;
            top: 20px;
            right: 20px;
        }
    }
`

export const WrapperPersonPopupContent = styled.div`
    position: relative;
    padding: 90px 220px 90px 620px;
    box-sizing: table;
    display: table;
    ${media.down('xl')} {
        padding-right: 50px;
    }
    ${media.down('lg')} {
        padding: 30px 50px 90px 50px;
    }
    ${media.down('md')} {
        padding: 0 20px 30px 20px;
    }
`

export const WrapperPersonPopupTitle = styled.div`
    width: 100%;
    margin-bottom: 40px;
    h6{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-bottom: 20px;
    }
    span{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }
    ${media.down('md')} {
        h6{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 10px;
        }
        span{
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 30px;
        }
    }
`

export const WrapperPersonPopupSection = styled.div`
    width: 100%;
    margin-bottom: 40px;
    display: none;
    span{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    ul{
        li{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.02em;
            text-align: left;
        }
    }
    ${media.down('md')} {
        span{
            font-size: 22px;
            line-height: 26px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
        ul{
            li{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
`
