import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperServiceNextStep = styled.div`
  width: 100%;
  padding: 60px 20px;
  background-color: #EDEFF1;
  text-align: center;
  margin-bottom: 100px;
  *{
      margin: 0;
      padding: 0;
  }
  p{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
  }
  h3{
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.01em;
    text-align: center;    
    margin-bottom: 20px;
  }
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;    
    max-width: 542px;
    display: block;
    margin: 0 auto 21px auto;
  }
  div{
      display: inline-block;
      float: none;
      margin: 0 auto;
      a div.wrapper{
        margin-top: 2.5px;
      }
  }
  ${media.down('lg')} {
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    p{
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 10px;
    }
    h3{
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    span{
      max-width: 335px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`