import styled from "styled-components";
import {media} from "../../atoms/theme";

export const PowerAppDescription = styled.div`
  width: 100%;
  max-width: 100%;

  ul {
    padding-left: 20px;
  }

  h2 {
    color: ${(props) => props.theme.colors.black};
    margin-top: 0;
    max-width: 218px;
  }

  ${media.up('sm')} {


    h2 {
      max-width: 100%;
    }
  }

  ${media.up('md')} {
    max-width: 560px;
  }

  ${media.up('lg')} {
    max-width: 708px;
  }
`
