import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {WrapperDescriptionHome} from "./styles";
import { useTranslation } from "react-i18next";
import { BIHeadDesc} from "./interfaces";
import ReactHtmlParser from 'react-html-parser';

export const HeadDescriptionHome: FunctionComponent<BIHeadDesc> = ({title, content, link, link_show}) => {
    const { t } = useTranslation();

    return <>
        <WrapperDescriptionHome>
            <h1>{title}</h1>
            {ReactHtmlParser(content)}
            {link_show && link &&<ActionArrowAtom link={link} text={t('see_more')}/>}
        </WrapperDescriptionHome>
    </>
}
