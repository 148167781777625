import React, {FunctionComponent} from "react";
import {Logo} from './styles';
import { useTranslation } from "react-i18next";

export const LogoAtom: FunctionComponent = () => {
    const { t } = useTranslation();

    return <div>
        <Logo>
            <a href={t('back_link')} />
        </Logo>
    </div>
}
