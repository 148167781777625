import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperPayBack = styled.div`

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const ItemPayBack = styled.div`
  padding-left: 20px;
  border-left: 6px solid #FF5A00;
  max-width: 162px;
  margin: 21px 5px 0 0;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
  }

  ${media.up("md")} {
    max-width: 400px;

    p {

      font-size: 34px;
      line-height: 42px;
    }
  }
`
export const LargeNumbers = styled.p`
  font-size: 40px;
  font-weight: bold;

  ${media.up("md")} {
    font-size: 80px !important;
    line-height: 80px !important;
  }
`
export const ContainerPayBack = styled.div`
  margin: 0 0 40px 0;
  color: #000000;

  ${media.up("md")} {
    margin: 0 0 111px 0;
  }
`
