import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperServiceNumberSolo = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 120px;
    ${media.down('xl')} {
        display: inline-block;
        max-wdith: 100%;
        margin-bottom: 40px;
    }
`

export const NumberCol = styled.div`
    min-width: 100px;
    margin-right: 30px;
    p{
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 4px 0 0 0;
    }
    ${media.down('xl')} {
        min-width: 100%;
        p{
            font-size: 30px;
            line-height: 38px;
            margin: 0 0 10px 0;
        }
    }
`

export const TitleCol = styled.div`
    min-width: 400px;
    margin-right: 80px;
    p{
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 52px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
    }
    ${media.down('xl')} {
        min-width: 100%;
        p{
            font-size: 24px;
            line-height: 38px;
            margin: 0 0 10px 0;
        }
    }
`

export const ContentCol = styled.div`
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        margin: 10px 0 0 0;

    }
    ${media.down('xl')} {
        min-width: 100%;
        p{
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 10px 0;
        }
    }
`
