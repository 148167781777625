import styled from "styled-components";
import { media } from "../../atoms/theme/media";

export const WrapperParthershipmMeans = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 60px;
  ${media.up("md")} {
  margin-top: 180px;
    margin-bottom: 160px;
  }
`
