import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'

export const AreasBreadcrumb: FunctionComponent = () => {
    const history = useHistory();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Microsoft Partnership</Link>
            <ArrowRightIcon />
            <Link to="/areas" onClick={() => { history.push('/areas');}}>Obszary</Link>
        </WrapperBreadcrumb>
    </>
}
