import React, {FunctionComponent} from "react";
import {WrapperServiceNav} from "./styles";
import {BIServiceNavProps} from "./interfaces";
import { Link, useHistory } from 'react-router-dom';


export const ServiceNav: FunctionComponent<BIServiceNavProps> = ({turn,box_title_1, box_title_2, box_title_3, box_title_4}) => {
    const history = useHistory();

    return <>
        <WrapperServiceNav>
            <Link to="/services/solution-assessment" className={`${turn == '0' ? "active-nav" : ""}`}>
                <i className="tech-icon"></i>
                <p>{box_title_1}</p>
            </Link>
            <Link to="/services/solution-concept" className={`${turn == '1' ? "active-nav" : ""}`}>
                <i className="tech-icon"></i>
                <p>{box_title_2}</p>
            </Link>
            <Link to="/services/project-delivery" className={`${turn == '2' ? "active-nav" : ""}`}>
                <i className="tech-icon"></i>
                <p>{box_title_3}</p>
            </Link>
            <Link to="/services/maintenance-and-further-development" className={`${turn == '3' ? "active-nav" : ""}`}>
                <i className="tech-icon"></i>
                <p>{box_title_4}</p>
            </Link>
        </WrapperServiceNav>
    </>
}
