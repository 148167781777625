import React, {FunctionComponent} from "react";
import {IconWindow} from "../../atoms/icon-window/icon-window";
import {WrapperSaleforceMeans, SaleforceMeansContent, SaleforceMeansImage} from "./styles";
import { ISaleforceMeans } from "./interface";
import ReactHtmlParser from 'react-html-parser';

export const SaleforceMeans: FunctionComponent<ISaleforceMeans> = ({title, content, image}) => {
    return <>
        <WrapperSaleforceMeans>
            <SaleforceMeansContent>
                <h2>{title}</h2>
                <p>{ReactHtmlParser(content)}</p>
            </SaleforceMeansContent>
            <SaleforceMeansImage>
                <img src={image} />
            </SaleforceMeansImage>
        </WrapperSaleforceMeans>
    </>
}