import styled from "styled-components";
import {media} from "../../atoms/theme";
import LogoGala from "../../../../assets/icons/logo-white-sygnet.svg";

export const WrapperBackgroundGalaLogo = styled.div`
  width: 120px;
  height: 120px;
  background-size: 120px;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  background-image: url("${LogoGala}");
  z-index: 10;

  ${media.up('xl')} {
    position: absolute;
    width: 190px;
    height: 190px;
    background-size: 190px;
    top: 105px;
    left: 169px;
  }
`
