import styled from "styled-components";
import {media} from "../../../ui/atoms/theme";

export const WrapperServiceListImg = styled.div`
    width: 100%;
    margin-bottom: 100px;
    .solo-article{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 120px;
    }
    ${media.down('lg')} {
        .solo-article{
            display: table;
            box-sizing: border-box;
            margin-bottom: 40px;
        }
    }
`

export const Content = styled.div`
    max-width: 590px;
    display: flex;
    justify-content: center;
    align-items: center; 
    order: 1;
    ${media.down('lg')} {
        width: 100%;
        max-width: 100%;
    }
`

export const ContentIn = styled.div`
    max-width: 590px;
    h3{
        width: 100%;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0 0 30px 0;
    }
    p{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    ${media.down('lg')} {
        h3{
            font-size: 30px;
            line-height: 38px;
            margin: 0 0 10px 0;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
`

export const Image = styled.div`
    order: 5;
    max-width: 480px;
    img{
        width: 100%;
    }
    ${media.down('xl')} {
        max-width: 276px;
    }
    ${media.down('lg')} {
        order: -1;
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
`