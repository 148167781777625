import React, {FunctionComponent} from "react";
import { Link, useHistory } from 'react-router-dom';
import {ArrowRightIcon} from '../../ui/atoms/arrow-right-icon'
import {WrapperBreadcrumb} from '../styles'
import { useTranslation } from "react-i18next";
import { BIGalaBreadcrumb } from "../interfaces"

export const GalaBreadcrumb: FunctionComponent<BIGalaBreadcrumb> = ({title, slug}) => {
    const history = useHistory();
    const { t } = useTranslation();

    return <>
        <WrapperBreadcrumb>
            <Link to="/" onClick={() => { history.push('/');}}>Microsoft Partnership</Link>
            <ArrowRightIcon />
            <Link to={slug} onClick={() => { history.push(slug);}}>{title}</Link>
        </WrapperBreadcrumb>
    </>
}
