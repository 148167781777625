import React, {FunctionComponent} from "react";
import { ImgBackgroundFull } from "./styles";



export const BackgroundHome: FunctionComponent = () => {
    return <>
        <ImgBackgroundFull/>
    </>
}
