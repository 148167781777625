import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerHeadHome = styled.div`
    width: 100%;
    box-sising: border-box;
    display: table;
    margin-top: 100px;

    ${media.up('md')} {
        display: flex;
        flex-direction: row;
    }
`
