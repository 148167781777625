import styled from "styled-components";
import {media} from "../../../ui/atoms/theme";

export const WrapperDescService = styled.div`
  margin-bottom: 40px;
  p {
    font-size: 18px;
    max-width: 591px;
    margin-bottom: 0;
  }

  ${media.up('md')} {
    margin-bottom: 80px;
    border-bottom: 6px solid ${(props) => props.theme.colors.primaryOrange};
    border: unset;

    p {
      font-size: 22px;
    }
  }
`
export const TitleServices = styled.div`
  position: relative;

  ${media.up('md')} {
    h1 {
      width: 40%;

      &:after {
        content: '';
        display: block;
        height: 6px;
        width: 60%;
        background: #FF5A00;
        position: absolute;
        right: 0;
        bottom: calc(50% - 6px);

      }
    }
  }

  ${media.up('lg')} {
    h1 {
      width: 583px;

      &:after {
        width: calc(100% - 623px);
        margin-left: 40px;
      }
    }
`
