import React, {FunctionComponent} from "react";
import {ContainerHeadHome } from "./styles";
import {HeadDescriptionHome} from "../../molecules/head-description-home";
import {LogoMicrosoftPartherHome} from "../../molecules/logo-microsofr-home";
import {BIHomeDesc} from "./interfaces";
import generateLink from "../../../../core/api/assets";
export const HeadDescLogo: FunctionComponent<BIHomeDesc> = ({title, content,link, link_show, image}) => {
    return <>
        <ContainerHeadHome >
            <HeadDescriptionHome 
                title={title}
                content={content}
                link={link}
                link_show={link_show}
            />
            <LogoMicrosoftPartherHome 
                image={image ? generateLink(image) : ''}
            />
        </ContainerHeadHome>
    </>
}