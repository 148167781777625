import React, {FunctionComponent} from "react";
import {ContainerHeadHome } from "./styles";
import {HeadDescriptionGala} from "../../molecules/head-description-gala";
import {LogoMicrosoftPartherGalaBox} from "../../molecules/logo-microsoft-gala";
import {BIGHeadGala} from "./interfaces"
export const HeadDescLogo: FunctionComponent<BIGHeadGala> = ({title}) => {
    return <>
        <ContainerHeadHome >
            <HeadDescriptionGala title={title}/>
            <LogoMicrosoftPartherGalaBox />
        </ContainerHeadHome>
    </>
}