import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import BiContainer from "../../ui/atoms/container";
import { HeadDescLogo } from "../../ui/organisms/head-gala-desc-logo/head-desc-logo";
import { BackgroundGala } from "../../ui/organisms/background-gala/background-gala";
import { AwardGala } from "../../ui/organisms/award-gala/award-gala";
import { MicrosoftGala } from "../../ui/organisms/microsoft-gala/microsoft-gala";
import { GalaBreadcrumb } from "../../breadcrumbs/gala"
import Contact from "../../ui/organisms/contact"
import { useTranslation } from "react-i18next";
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'
import { useLocation } from 'react-router-dom';
import getSlug from "../../../core/router/getSlug";
import { PopupContact } from '../../ui/molecules/popup-contact';

export const GalaPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [url, setUrl] = useState("");
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setUrl(response.data.uri);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BiContainer>
        <GalaBreadcrumb 
            title={content['title']}
            slug={url}
        />
        <HeadDescLogo 
            title={content['header_-_title']}
        />
        </BiContainer>
        <BackgroundGala />
        <BiContainer>
        <MicrosoftGala 
            title={content['section_1_-_title']}
            content={content['section_1_-_content']}
        />
        <AwardGala 
            title={content['section_2_-_title']}
            content={content['section_2_-_content']}
            url={content['section_2_-_link']}
        />
        <PopupContact
            title={content['contact_title']}
            content={content['contact_content']}
            text_link={t('contact')}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
        </BiContainer>
    </>
}
