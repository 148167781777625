import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {media} from "../../atoms/theme";
import {IBackgroundAreas} from "./interface"

export const BackgroundAreasImage: FunctionComponent<IBackgroundAreas> = ({banner}) => {

    const WrapperBackgroundAreasImage = styled.div`
        background-image: url("${banner}");
        background-repeat: no-repeat;
        height: 335px;
        background-size: cover;
        margin: 34px 0;

        ${media.up('md')} {
            height: 400px;

            background-image: url("${banner}");
        }
    `

    return <>
        <WrapperBackgroundAreasImage/>
    </>
}
