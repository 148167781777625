import React, {FunctionComponent} from "react";
import {WrapperServiceNumberSolo, NumberCol, TitleCol, ContentCol} from "./styles";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {BINumberItem} from "./interface"
import ReactHtmlParser from 'react-html-parser';

export const ServicesNumberList: FunctionComponent<BINumberItem> = ({items, link, title_link}) => {

    console.log(link);
    return <>
        {Object.keys(items).map((keyName, i) => (
            <WrapperServiceNumberSolo>
                <NumberCol>
                    <p>{i + 1 < 10 ? `0${i + 1}` : i + 1}</p>
                </NumberCol>
                <TitleCol>
                    <p>{items[keyName].data['title']}</p>
                </TitleCol>
                <ContentCol>
                    <p>{ReactHtmlParser(items[keyName].data['content'])}</p>
                </ContentCol>
            </WrapperServiceNumberSolo>
        ))}
        {(link !== null || title_link !== null) &&<WrapperServiceNumberSolo>
            <ContentCol>
                <ActionArrowAtom link={link} text={title_link}/>
            </ContentCol>
        </WrapperServiceNumberSolo>}
    </>
}
