import React, {FunctionComponent} from "react";
import {ContainerPayBack, ItemPayBack, LargeNumbers, WrapperPayBack} from "./styles";
import {IAboutTeam} from "../../../pages/areas/interfaces";
import { IAboutArea } from "./interface";
import ReactHtmlParser from 'react-html-parser';
export const AboutAreaTeam: FunctionComponent<IAboutArea> = ({title, value_box_1, content_box_1, value_box_2, content_box_2, value_box_3, content_box_3}) => {
    return <>
        <ContainerPayBack>
            <div><h2>{title}</h2>
                <WrapperPayBack>
                    <ItemPayBack>
                        <LargeNumbers>{value_box_1}</LargeNumbers>
                        <p>{ReactHtmlParser(content_box_1)}</p>
                    </ItemPayBack>
                    <ItemPayBack>
                        <LargeNumbers>{value_box_2}</LargeNumbers>
                        <p>{ReactHtmlParser(content_box_2)}</p>
                    </ItemPayBack>
                    <ItemPayBack>
                        <LargeNumbers>{value_box_3}</LargeNumbers>
                        <p>{ReactHtmlParser(content_box_3)}</p>
                    </ItemPayBack>
                </WrapperPayBack>
            </div>
        </ContainerPayBack>
    </>
}
