import styled from "styled-components";
import {media} from "../../atoms/theme";

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 80px;
    padding: 0 30px;
    margin-bottom: 30px;
    ${media.up('lg')} {
        padding: 10px 30px;
    }
`
export const HeaderWrapperRight = styled.div`
    float: right;
    height: 40px;
    display: none;

    div{
        float: left;
    }
    
    ${media.up('lg')} {
        display: table;   
    }
`
