import React, {FunctionComponent} from "react";
import { WrapperVimeoGala } from "./styles";
import { BIVimeoGala } from "./interfaces"

export const VimeoGala: FunctionComponent<BIVimeoGala> = ({url}) => {
    return <>
        <WrapperVimeoGala>
            <iframe src={url} allow="autoplay; fullscreen; picture-in-picture"></iframe>
        </WrapperVimeoGala>
    </>
}