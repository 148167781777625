import React, {FunctionComponent} from "react";
import {ContainerAwardGala } from "./styles";
import {VimeoGala} from "../../molecules/vimeo-gala";
import { useTranslation } from "react-i18next";
import { BIAwardGala } from "./interfaces"
import ReactHtmlParser from 'react-html-parser';

export const AwardGala: FunctionComponent<BIAwardGala> = ({title, content, url}) => {
    const { t } = useTranslation();

    return <>
        <ContainerAwardGala >
            <h2>{title}</h2>
            <p>{ReactHtmlParser(content)}</p>
            <VimeoGala url={url} />
        </ContainerAwardGala>
    </>
}