import {createGlobalStyle} from 'styled-components';
import {media} from "./media";
import BillenniumRegular from '../../../../assets/fonts/Billennium-Regular.woff';
import BillenniumSemiBold from '../../../../assets/fonts/Billennium-SemiBold.woff';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//temporary font
const GlobalStyle = createGlobalStyle`@font-face {
  font-family: 'Billennium';
  font-weight: 400;
  src: url(${BillenniumRegular}) format('woff');
}



@font-face {
  font-weight: 600;
  font-family: 'Billennium';
  src: url(${BillenniumSemiBold}) format('woff');
}

@font-face {
  font-weight: 700;
  font-family: 'Billennium';
  src: url(${BillenniumSemiBold}) format('woff');
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
 
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
body {
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSize};
  margin: 0;
  padding: 0;
  line-height: normal;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primaryGray};
  -webkit-font-smoothing: antialiased;
  ${media.down('lg')} {
    font-size: ${(props) => props.theme.mobile.fontSize};
  }
}

h1 {
  font-size: ${(props) => props.theme.desktop.h1.fontSize};
  font-weight: ${(props) => props.theme.desktop.h1.fontWeight};
  line-height: ${(props) => props.theme.desktop.h1.lineHeight};
  letter-spacing: ${(props) => props.theme.desktop.h1.letterSpacing};;
  color: ${(props) => props.theme.colors.black};
  ${media.down('lg')} {
    font-size: ${(props) => props.theme.mobile.h1.fontSize};
    font-weight: ${(props) => props.theme.mobile.h1.fontWeight};
    line-height: ${(props) => props.theme.mobile.h1.lineHeight};
    letter-spacing: ${(props) => props.theme.mobile.h1.letterSpacing};;
  }
}

h2 {
  font-size: ${(props) => props.theme.desktop.h2.fontSize};
  font-weight: ${(props) => props.theme.desktop.h2.fontWeight};
  line-height: ${(props) => props.theme.desktop.h2.lineHeight};
  letter-spacing: ${(props) => props.theme.desktop.h2.letterSpacing};;
  ${media.down('lg')} {
    font-size: ${(props) => props.theme.mobile.h2.fontSize};
    font-weight: ${(props) => props.theme.mobile.h2.fontWeight};
    line-height: ${(props) => props.theme.mobile.h2.lineHeight};
    letter-spacing: ${(props) => props.theme.mobile.h2.letterSpacing};;
  }
}

h3 {
  font-size: ${(props) => props.theme.desktop.h3.fontSize};
  font-weight: ${(props) => props.theme.desktop.h3.fontWeight};
  line-height: ${(props) => props.theme.desktop.h3.lineHeight};
  letter-spacing: ${(props) => props.theme.desktop.h3.letterSpacing};;
  color: ${(props) => props.theme.colors.black};
  ${media.down('lg')} {
    font-size: ${(props) => props.theme.mobile.h3.fontSize};
    font-weight: ${(props) => props.theme.mobile.h3.fontWeight};
    line-height: ${(props) => props.theme.mobile.h3.lineHeight};
    letter-spacing: ${(props) => props.theme.mobile.h3.letterSpacing};;
  }
}

input, textarea {
  font-family: ${(props) => props.theme.font};
  border: 1px solid #A4A6A0;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  outline: none;
  resize: none;
  &:focus{
    color: ${(props) => props.theme.colors.black};
  }
}
`;

export default GlobalStyle;
