import axios from 'axios';
import React, {FunctionComponent, useState} from "react";
import { Container, WrapperContactPopup, SoloContent, Checkboxes, CheckboxesList, SoloCheck, BottomPopup, Inputs, Thanks} from "./styles";
import { IPopupContact } from "./interface";
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga'
import { isOpenModalContact } from "../../atoms/button-header/button-header";

export const PopupContact: FunctionComponent<IPopupContact> = ({title, content, text_link, top, send_to, pardot_link}) => {
    const [isStep, setStep] = useState(0);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [company_name,setCompanyName] = useState("");
    const [contentField,setContentField] = useState("");
    const [errors,setErrors] = useState({});
    const [isOpenModal, setOpenModal] = isOpenModalContact();
    const history = useHistory();

    const handleSubmit = (evt) => {
        const { REACT_APP_API_HOST } = process.env;
        evt.preventDefault();
        const data = {
            name: name,
            email: email,
            email_from: 'bok@billennium.com',
            company_name: company_name,
            content: contentField,
            send_to: send_to ? send_to : '',
            pardot_link: pardot_link ? pardot_link : ''
        };
        axios.post(`${REACT_APP_API_HOST}api/send-mail`, data)
            .then(res=>successSend(res.data))
            .catch(err => console.log(err));
    }

    const successSend = (res) => {
        if(res.status === 200){
            setName('')
            setEmail('')
            setCompanyName('')
            setContentField('')
            setErrors({});
            setStep(2)
            ReactGA.event({
                category: 'Form submit',
                action: 'Microsoft Partnership'
            });
        }else{
            setErrors(res.validate_err);
            console.log(typeof(res.validate_err.content));
            (typeof(res.validate_err.content) !== 'undefined') ? setStep(0) : setStep(1);
        }
    }

    const finishForm = () => {
        setOpenModal(false);
        setStep(0);
    }

    return <div id="contact">
        {top && top ? <Container top={'120px'}>
            <h4>{title}</h4>
            <p>{content}</p>
            <a onClick={() => setOpenModal(true)}>{text_link}</a>
        </Container> :
        <Container top={'0'}>
            <h4>{title}</h4>
            <p>{content}</p>
            <a onClick={() => setOpenModal(true)}>{text_link}</a>
        </Container>}
        {isOpenModal &&<WrapperContactPopup>
            <div className="close-icon" onClick={() => setOpenModal(false)}/>
            {isStep === 0 &&<SoloContent>
                <h4>Info</h4>
                <Checkboxes>
                    <h5>Tell us more about your product</h5>
                    <textarea placeholder="Your message" name="content" onChange={e => setContentField(e.target.value)} value={contentField}/>
                    <span>{errors['content']}</span>
                    <BottomPopup>
                        <a onClick={() => setStep(1)} className="solo-button">Next</a>
                    </BottomPopup>
                </Checkboxes>
            </SoloContent>}
            {isStep === 1 &&<SoloContent>
                <h4>Contact</h4>
                <Checkboxes>
                    <h5>Leave us your contact</h5>
                    <Inputs>
                        <input name="name" placeholder="Your name" onChange={e => setName(e.target.value)} value={name}/>
                        <span>{errors['name']}</span>
                        <input name="email" placeholder="Your business email" onChange={e => setEmail(e.target.value)} value={email}/>
                        <span>{errors['email']}</span>
                        <input name="company" placeholder="Your company name" onChange={e => setCompanyName(e.target.value)} value={company_name}/>
                        <span>{errors['company_name']}</span>
                    </Inputs>
                    <BottomPopup>
                        <a onClick={() => setStep(0)} className="empty">Back</a>
                        <a onClick={handleSubmit}>Request contact</a>
                    </BottomPopup>
                </Checkboxes>
            </SoloContent>}
            {isStep === 2 &&<SoloContent>
                <Thanks>
                    <h5>Thank you for your time!</h5>
                    <p>Your request has been submitted.<br />We will contact you to talk about details</p>
                </Thanks>
                <BottomPopup>
                    <Link to="/" onClick={finishForm} className="link">Go to Microsoft Partnership</Link>
                </BottomPopup>
            </SoloContent>}
        </WrapperContactPopup>}
    </div>
}
