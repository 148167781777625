import React, {FunctionComponent} from "react";
import {WrapperIconWindow, IconRectangleWindow} from "./styles";

export const IconWindow: FunctionComponent = () => {
    return <>
        <WrapperIconWindow>
            <IconRectangleWindow/>
        </WrapperIconWindow>
    </>
}
