import React, {FunctionComponent} from "react";
import { ContainerItems, WrapperSoloItem, WrapperItems } from "./styles";
import { ICertifications } from "./interface";
import generateLink from "../../../../core/api/assets";

export const Certifications: FunctionComponent<ICertifications> = ({items, title, content}) => {
    return <>
        <ContainerItems>
            <h4>{title}</h4>
            <p>{content}</p>
            <WrapperItems>
                {Object.keys(items).map((keyName, i) => (
                    <WrapperSoloItem>
                        <img src={generateLink(items[keyName].data['image'])} />
                    </WrapperSoloItem>
                ))}
            </WrapperItems>
        </ContainerItems>
    </>
}
