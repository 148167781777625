import styled from "styled-components";
import ImgBackgroundHomeMobile from "../../../../assets/images/background-Img-home-mobile.png";
import {media} from "../theme";
import ImgBackgroundHome from "../../../../assets/images/background-Img-home.jpg";

export const ImgBackgroundFull = styled.div`
  margin-top: 60px;
  background-image: url("${ImgBackgroundHome}");
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  background-size: cover;
  position: relative;
  z-index: 10;
  margin-bottom: 40px;

  ${media.up('md')} {
    margin-top: 0px;
    background-image: url("${ImgBackgroundHome}");
    background-position: 50% 50%;
    height: 400px;
    margin-bottom: 160px;
  }
`
