import styled from "styled-components";
import {media} from "../../atoms/theme";
import MicrosoftImage from "../../../../assets/icons/microsoft-icon-gala.png";

export const WrapperLogoMicrosoftGala = styled.div`
  width: 100%;
  margin-top: 30px;
  border-top: 6px solid ${(props) => props.theme.colors.primaryOrange};
  padding-top: 40px;
  box-sising: border-box;
  display: table;

  ${media.up('md')} {
    border-top: none;
    padding-top: 0;
  }
`
export const LogoMicrosoftPartherGala = styled.div`
  background-image: url("${MicrosoftImage}");
  background-repeat: no-repeat;
  width: 245px;
  height: 69px;
  background-size: 245px;
  display: block;
  margin: 0 auto 100px auto;

  ${media.up('sm')} {
    display: inherit;
  }

  ${media.up('md')} {
    margin-right: 100px;
  }

  ${media.up('lg')} {
    width: 473px;
    height: 132px;
    background-size: 473px;
    float: right;
  }
`
