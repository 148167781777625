import React, {FunctionComponent, useState} from "react";
import {WrapperSoloPerson, WrapperPersonPopup, WrapperPersonPopupContent, WrapperPersonPopupTitle, WrapperPersonPopupSection} from "./styles";
import { BISoloPerson } from './interfaces'
import { ActionArrowAtom } from "../../atoms/action-arrow";
import PersonPopup1 from "../../../../assets/images/PersonPopup1.png";
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import {media} from "../../atoms/theme";

export const SoloPerson: FunctionComponent<BISoloPerson> = ({image, name, position, portfolio_title, portfolio_desc, help_title, help_desc, image_large}) => {
    const [isPopupOpen, setOpenPopup] = useState(false);

    const WrapperPersonPopupImage = styled.div`
        width: 500px;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        background-image: url("${image_large}");
        img{
            display: none;
        }
        ${media.down('lg')} {
            position: relative;
            display: block;
            min-height: auto;
            margin-bottom: 60px;
            width: 100%;
            img{
                width: 100%;
                display: block;
            }
        }
        ${media.down('md')} {
            margin-bottom: 30px;
        }
    `

    return <>
        <WrapperSoloPerson>
            <img src={image_large}/>
            <p>{name}</p>
            <span>{position}</span>
        </WrapperSoloPerson>
        {isPopupOpen &&<WrapperPersonPopup>
            <div className="close-icon" onClick={() => setOpenPopup(prev => !prev)}></div>
            <WrapperPersonPopupContent>
                <WrapperPersonPopupTitle>
                    <h6>{name}</h6>
                    <span>{position}</span>
                </WrapperPersonPopupTitle>
                <WrapperPersonPopupImage>
                    <img src={image} />
                </WrapperPersonPopupImage>
                <WrapperPersonPopupSection>
                    <span>{portfolio_title}</span>
                    {ReactHtmlParser(portfolio_desc)}
                </WrapperPersonPopupSection>
                <WrapperPersonPopupSection>
                    <span>{help_title}</span>
                    {ReactHtmlParser(help_desc)}
                </WrapperPersonPopupSection>
            </WrapperPersonPopupContent>
        </WrapperPersonPopup>}
    </>
}