import axios from 'axios';
import React, {FunctionComponent, useState, useEffect} from "react";
import { BackgroundAreasImage } from "../background-areas-image";
import { SolutionAreas } from "../../organisms/solution-areas/solution-areas";
import { AboutAreaTeam } from "../../organisms/about-area-team/about-area-team";
import generateLink from "../../../../core/api/assets"
import { useGlobalLang } from '../lang-header/useGlobalLang'
import { ISoloArea } from './interface'
import { PopupContact } from '../popup-contact';
import { useTranslation } from 'react-i18next';
import { CaseStudy } from '../../organisms/case-study/case-study';


export const SoloArea: FunctionComponent<ISoloArea> = ({ id, contact_title, contact_content, contact_send_to, contact_pardot_link }) => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [solutions, setSolution] = useState([]);
    const [caseStudies, setCaseStudies] = useState([]);
    const [lang, setLang] = useGlobalLang();

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/entry/solo-by-id/${id}`)
        .then(response => {
            setContent({...response.data.data});
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/solutions/area/${id}`)
        .then(response => {
            setSolution(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/case_study/area/${id}`)
        .then(response => {
            setCaseStudies(response.data);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    return <>
        <BackgroundAreasImage
            banner={content['banner'] ? generateLink(content['banner']) : ''}
        />
        <AboutAreaTeam
            title={content['section_1_-_title']}
            value_box_1={content['value_box_1_-_title']}
            content_box_1={content['value_box_1_-_undertitle']}
            value_box_2={content['value_box_2_-_title']}
            content_box_2={content['value_box_2_-_undertitle']}
            value_box_3={content['value_box_3_-_title']}
            content_box_3={content['value_box_1_-_undertitle']}
        />
        <SolutionAreas 
            title={content['section_2_-_title']}
            content={content['section_2_-_content']}
            solutions={solutions}
        />
        <CaseStudy
            title={content['section_3_-_title']}
            case_study={caseStudies}
        />
        <PopupContact
            title={contact_title}
            content={contact_content}
            text_link={t('contact')}
            send_to={contact_send_to}
            pardot_link={contact_pardot_link}
        />
    </>
}