import React, {FunctionComponent} from "react";
import {WrapperBackgroundGalaImage} from "./styles";
import {IBackgroundSaleforceImage} from './interface'

export const BackgroundSaleforceImage: FunctionComponent<IBackgroundSaleforceImage> = ({banner}) => {
    return <>
       <WrapperBackgroundGalaImage>
           <img src={banner} />
        </WrapperBackgroundGalaImage>
    </>
}
