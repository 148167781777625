import styled from "styled-components";
import {media} from "../../../ui/atoms/theme";
import TechImg from '../../../../assets/icons/tech-orange.svg';
import TechImgWhite from '../../../../assets/icons/tech-white.svg';
import TechImgGray from '../../../../assets/icons/tech-gray.svg';
import ArrowRight from '../../../../assets/icons/arrow-right-gray.svg';

export const WrapperServiceNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
  a{
    cursor: pointer;
    flex: 1 0 21%;
    max-width: 23%;
    margin-bottom: 40px;
    background: #EDEFF1;
    padding: 0 34.5px;
    height: 124px;
    position: relative;
    display: table;
    color: #000;
    text-decoration: none;
    &:after{
        content: '';
        position: absolute;
        right: -15px;
        top: calc(50% - 20px);
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 15px solid #EDEFF1;
        clear: both;
    }
    &:nth-child(4){
        &:after{
          border-left: 15px solid transparent;
        }
    }
    i{
        content: url("${TechImg}");
        width: 56px;
        height: 64px;
        float: left;
        margin: 30px 15px 30px 0;
    }
    p{
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;    
        display: table-cell;
        vertical-align: middle; 
    }
  }
  .active-nav{
      background-color: ${(props) => props.theme.colors.primaryOrange};
      &:after{
        border-left: 15px solid ${(props) => props.theme.colors.primaryOrange};
      }
      &:nth-child(4){
        &:after{
          border-left: 15px solid transparent;
        }
      }
      i{
        content: url("${TechImgWhite}");
      }
      p{
          color: #fff;
      }
  }
  ${media.down('xl')} {
    gap: 10px;
    border-bottom: 6px solid ${(props) => props.theme.colors.primaryOrange};
    a{
      max-width: 56px;
      height: 63px;
      background: transparent;
      padding: 0;
      p{
        display: none;
      }
      i{
        content: url("${TechImgGray}");
        margin: 0 auto;
      }
      &:after{
        content: url("${ArrowRight}");
        border: none;
        width: 4px;
        height: 8px;
        top: calc(50% - 12px);
        right: -95px;
      }
      &:nth-child(4){
        &:after{
          content: '';
        }
      }
    }
    .active-nav{
      background: transparent;
      i{
        content: url("${TechImg}");
        margin: 0 auto;
      }
      &:after{
        border: none;
      }
    }
  }
  ${media.down('lg')} {
    a{
      &:after{
        right: -55px;
      }
    }
  }
  ${media.down('md')} {
    a{
      &:after{
        right: -25px;
      }
    }
  }
  ${media.down('sm')} {
    a{
      &:after{
        right: -20px;
      }
    }
  }
`