import styled from "styled-components";
import {media} from "../../atoms/theme";
import BackgroundImgSaleforceMobile from "../../../../assets/images/background-saleforce-mobile.png";
import BackgroundImgSaleforce from "../../../../assets/images/background-saleforce.png";

export const WrapperBackgroundGalaImage = styled.div`
  max-width: 1440px;
  background-size: cover;
  position: relative;
  z-index: 10;
  margin: 60px auto 40px auto;
  img{
    width: 100%;
  }
`
