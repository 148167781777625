import getPageComponent from './getPageComponent';

const getApiRoutes = (routes) => {
    let pages = {};
    Object.keys(routes).map((keyName, i) => (
      pages = {
        ...pages,
        [routes[keyName].id]: {
          id: routes[keyName].id,
          name: routes[keyName].data.title,
          slug: routes[keyName].uri,
          component: getPageComponent(routes[keyName].data.template),
        }
      }
  ))
  const pagesArray = Object.entries(pages).map(([_, v]) => v);
  return pagesArray;
}

export default getApiRoutes;