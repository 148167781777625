import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from "react";
import Areas from "../../../ui/organisms/areas/inedx";
import People from "../../../ui/organisms/people";
import CaseStudy from "../../../ui/organisms/case-study"
import ServieListImg from "../../../ui/organisms/service-list-img"
import ServiceNextStep from "../../../ui/organisms/service-next-step"
import ServiceNav from "../../../ui/organisms/service-nav"
import Contact from "../../../ui/organisms/contact"
import {articles} from "./articles"
import {TitleServices, WrapperDescService} from "./styles";
import { SoloServiceBreadcrumb } from '../../../breadcrumbs/services/solo-service';
import BiContainer from '../../../ui/atoms/container/BiContainer';
import { useGlobalLang } from '../../../ui/molecules/lang-header/useGlobalLang'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import generateLink from "../../../../core/api/assets"
import getSlug from "../../../../core/router/getSlug";
import { PopupContact } from '../../../ui/molecules/popup-contact';

export const SolutionConceptPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [people, setPeople] = useState([]);
    const [pageid, setPageId] = useState('');
    const [case_study, setCaseStudy] = useState([]);
    const [servicesBox, setServicesBox] = useState([]);
    const [lang, setLang] = useGlobalLang();
    const location = useLocation();
    const [slug, setSlug] = useState(getSlug(location.pathname));

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/entry/solo-by-slug/${slug}`)
        .then(response => {
            setContent({...response.data.data});
            setPageId(response.data.id);
        }) 
        .catch(err => {
            console.log(err);
        })
    }, [lang])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        if(pageid !== ''){
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/case_study/page/${pageid}`)
                .then(response => {
                    setCaseStudy(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                }) 
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/people/page/${pageid}`)
                .then(response => {
                    setPeople(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
            axios.get(`${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection/services_box/page/${pageid}`)
                .then(response => {
                    setServicesBox(response.data);
                }) 
                .catch(err => {
                    console.log(err);
                })
        }
    }, [pageid])

    return <>
        <BiContainer>
        <SoloServiceBreadcrumb 
            title={content['title']}
            slug={slug}
        />
        <WrapperDescService>
            <TitleServices>
                <h1>{content['header_-_title']}</h1>
            </TitleServices>
            <p>{ReactHtmlParser(content['header_-_content'])}</p>
        </WrapperDescService>
        <ServiceNav 
            turn="1"
            box_title_1={content['service_nav_1_-_title']}
            box_title_2={content['service_nav_2_-_title']}
            box_title_3={content['service_nav_3_-_title']}
            box_title_4={content['service_nav_4_-_title']}
        />
        <ServieListImg
            items={servicesBox}
        />
        <People
            title={content['section_1_-_title']}
            content={content['section_1_-_content']}
            people={people}
        />
        <CaseStudy
            title={content['section_2_-_title']}
            case_study={case_study}
        />
        <ServiceNextStep
            undertitle={content['next_step_-_intro']}
            title={content['next_step_-_title']}
            content={content['next_step_-_content']}
            link_text={content['next_step_-_link_title']}
            link={content['next_step_-_link']}
        />
        <PopupContact
            title={content['contact_title']}
            content={content['contact_content']}
            text_link={t('contact')}
            send_to={content['contact_send_to']}
            pardot_link={content['pardot_link']}
        />
        </BiContainer>
    </>
}
