import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import {ISolution} from "../../../pages/areas/interfaces";
import { ContainerItemsSolution, HeaderContent, WrapperItemsSolution } from "./styles";
import { SoloSolution } from "../../molecules/solo-solution";
import { ISolutionAreas } from "./interface";
import generateLink from "../../../../core/api/assets"


export const SolutionAreas: FunctionComponent<ISolutionAreas> = ({title, content, solutions}) => {

    return <>
        <ContainerItemsSolution>
                <div>
                    <HeaderContent>
                        <h2>{title}</h2>
                        <p>{content}</p>
                    </HeaderContent>
                    <WrapperItemsSolution>
                        {Object.keys(solutions).map((keyName, i) => (
                            <SoloSolution 
                                id={solutions[keyName].id}
                                title={solutions[keyName].data['title']}
                                content={solutions[keyName].data['content']}
                                points={solutions[keyName].data['list']}
                                title_popup={solutions[keyName].data['header_-_title']}
                                content_popup={solutions[keyName].data['header_-_content']}
                                image={solutions[keyName].data['image'] ? generateLink(solutions[keyName].data['img']) : ''}
                                title_section_1={solutions[keyName].data['section_1_-_title']}
                                list_section_1={solutions[keyName].data['section_1_-_list']}
                                title_section_2={solutions[keyName].data['section_2_-_title']}
                            />
                        ))}
                    </WrapperItemsSolution>
                </div>
        </ContainerItemsSolution>
    </>
}
