import axios from 'axios';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import BiContainer from "../../ui/atoms/container";
import HeaderLine from "../../ui/organisms/header-line";
import Header from "../../ui/organisms/header";
import Footer from "../../ui/organisms/footer";
import ScrollToTop from './scroll-up-routes'
import getApiRoutes from '../../../core/router/routes'
import { useGlobalLang } from '../../ui/molecules/lang-header/useGlobalLang'

export const BaseLayout: FunctionComponent = () => {
    const [lang, setLang] = useGlobalLang();
    const [routes, setRoutes] = useState({});

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH, REACT_APP_API_SITE } = process.env;
        let routes_url = `${REACT_APP_API_FULL_PATH}/${REACT_APP_API_SITE}${lang}/collection-pages`;
        axios.get(`${routes_url}`)
            .then(response => {
                setRoutes({...getApiRoutes(response.data)});
            }) 
            .catch(err => {
                console.log(err);
            }) 
    }, [lang])

    return <>
        <Router>
            <HeaderLine/>
            <Header/>
                <ScrollToTop />
                <Switch>
                    {Object.keys(routes).map((keyName, i) => (
                        <Route key={routes[keyName].id} exact path={routes[keyName].slug} component={routes[keyName].component}/>
                    ))}
                </Switch>
            <BiContainer>
                <Footer/>
            </BiContainer>
        </Router>
    </>;
};
