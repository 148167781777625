import React, {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow";
import {WrapperServiceNextStep} from "./styles";
import {BIServiceNextStepProps} from "./interfaces";
import ReactHtmlParser from 'react-html-parser';

export const ServiceNextStep: FunctionComponent<BIServiceNextStepProps> = ({undertitle, title, content, link_text, link}) => {
    return <>
        <WrapperServiceNextStep>
            <p>{undertitle}</p>
            <h3>{title}</h3>
            <span>{ReactHtmlParser(content)}</span>
            <ActionArrowAtom  link={link} text={link_text}/>
        </WrapperServiceNextStep>
    </>
}
