import React,{FunctionComponent} from "react";

import {LargeNumbers, ItemPayBack, ContainerPayBack,WrapperPayBack } from "./styles";
import {  } from "./styles";
import ReactHtmlParser from 'react-html-parser';
import { IWillPayBack } from "./interface";


export const WillPayBack: FunctionComponent<IWillPayBack> = ({title, value_box_1, content_box_1, value_box_2, content_box_2, value_box_3, content_box_3}) => {
        return <>
            <ContainerPayBack>
                <h2>{title}</h2>
                <WrapperPayBack>
                    <ItemPayBack>
                        <LargeNumbers>{value_box_1}</LargeNumbers>
                        <p>{ReactHtmlParser(content_box_1)}</p>
                    </ItemPayBack>
                    <ItemPayBack>
                        <LargeNumbers>{value_box_2}</LargeNumbers>
                        <p>{ReactHtmlParser(content_box_2)}</p>
                    </ItemPayBack>
                    <ItemPayBack>
                        <LargeNumbers>{value_box_3}</LargeNumbers>
                        <p>{ReactHtmlParser(content_box_3)}</p>
                    </ItemPayBack>
                </WrapperPayBack>
            </ContainerPayBack>
        </>
}
