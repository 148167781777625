import styled from "styled-components";
import {media} from "../../atoms/theme";

export const WrapperBackgroundGala = styled.div`
  width: 100%;
  height: 250px;
  background-color: ${(props) => props.theme.colors.primaryOrange};
  position: relative;

  ${media.up('xl')} {
    height: 400px;
  }
`
