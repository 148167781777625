import styled from "styled-components";
import {media} from "../../atoms/theme";


export const WrapperDescriptionAreas = styled.div`
  position: relative;

  p {
    max-width: 692px
  }
}

${media.up('md')} {
  h1 {
    width: 80%;

    &:after {
      content: '';
      display: block;
      height: 6px;
      width: 60%;
      background: #FF5A00;
      position: absolute;
      right: 0;
      bottom: calc(78% - 6px);

    }
  }
}

${media.up('lg')} {
  h1 {
    width: 583px;

    &:after {
      width: calc(100% - 200px);
      margin-left: 40px;
    }
  }
`
