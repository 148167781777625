import styled from "styled-components";

export const WrapperCaseStudy = styled.div`
  width: 100%;
  margin-bottom: 100px;
  h1{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left; 
  }
`

export const WrapperCaseStudyList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
`
