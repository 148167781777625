import styled from "styled-components";
import {media} from "../../atoms/theme";

export const ContainerItemsSolution = styled.div`
  width: 100%;
  display: table;
  box-sizing: border-box;
  margin-bottom: 64px;
`

export const WrapperItemsSolution = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
`

export const HeaderContent = styled.div`
  width: 100%;
  margin-bottom: 67px;
  h2{
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
  }
  p{
    max-width: 789px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #25282B;
    margin: 30px 0 67px 0;
  }
  ${media.down('lg')} {
    margin-bottom: 40px;
    h2{
      font-size: 30px;
      line-height: 38px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
    }
  }
`
