import {FunctionComponent} from "react";
import {ActionArrowAtom} from "../../atoms/action-arrow/action-arrow-atom";
import {IServicesItem} from './interfaces'
import { ContainerItemServices, DescItemService, IconServices, WrapperItemService } from "./styles";
import ReactHtmlParser from 'react-html-parser';


export const ListOfServices: FunctionComponent<IServicesItem> = ({icon_service1, icon_service2, icon_service3, icon_service4, title_service1, content_service1, slug_service1, title_url_service1, title_service2, content_service2, slug_service2, title_url_service2, title_service3, content_service3, slug_service3, title_url_service3, title_service4, content_service4, slug_service4, title_url_service4}) => {
    return <>
        <ContainerItemServices>
            <WrapperItemService>
                <IconServices thumb={icon_service1}/>
                <DescItemService>
                    <h2>{title_service1}</h2>
                    {ReactHtmlParser(content_service1)}
                    {slug_service1 && title_url_service1 &&<ActionArrowAtom link={slug_service1} text={title_url_service1}/>}
                </DescItemService>
            </WrapperItemService>
            <WrapperItemService>
            <IconServices thumb={icon_service2}/>
                <DescItemService>
                    <h2>{title_service2}</h2>
                    {ReactHtmlParser(content_service2)}
                    {slug_service2 && title_url_service2 &&<ActionArrowAtom link={slug_service2} text={title_url_service2}/>}
                </DescItemService>
            </WrapperItemService>
            <WrapperItemService>
            <IconServices thumb={icon_service3}/>
                <DescItemService>
                    <h2>{title_service3}</h2>
                    {ReactHtmlParser(content_service3)}
                    {slug_service3 && title_url_service3 &&<ActionArrowAtom link={slug_service3} text={title_url_service3}/>}
                </DescItemService>
            </WrapperItemService>
            <WrapperItemService>
                <IconServices thumb={icon_service4}/>
                <DescItemService>
                    <h2>{title_service4}</h2>
                    {ReactHtmlParser(content_service4)}
                    {slug_service4 && title_url_service4 &&<ActionArrowAtom link={slug_service4} text={title_url_service4}/>}
                </DescItemService>
            </WrapperItemService>
        </ContainerItemServices>
    </>
}
