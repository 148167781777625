import styled from "styled-components";
import {media} from "../../atoms/theme";


export const WrapperDescriptionHome = styled.div`
  width: 100%;
  max-width: 490px;

  h1 {
    margin-top: 0;
    display: inline-block;
    background: #fff;
    z-index: 1;
    position: relative;
    max-width: 250px;
  }

  p {
    max-width: 80%;
  }

  ${media.up('lg')} {
    p {
      max-width: 100%;
    }
  }
  ${media.up('md')} {
    h1{
      max-width: 100%;
    }
  }
`
