import React, {FunctionComponent} from "react";
import {Button} from './styles'
import { useTranslation } from "react-i18next";
import {createGlobalState} from 'react-use';

export const isOpenModalContact = createGlobalState<boolean>(false);

export const ButtonHeader: FunctionComponent = () => {
    const { t } = useTranslation();
    const [isOpenModal, setOpenModal] = isOpenModalContact();

    return <div>
        <Button>
            <a onClick={() => setOpenModal(true)}>{t('contact')}</a>
        </Button>
    </div>
}